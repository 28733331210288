/**
 * Converts a hex color code to an RGB object.
 * @param hex - The hex color code.
 * @returns An object with r, g, and b properties.
 */
export function hexToRgbObj(hex: string) {
  // Remove the # symbol if present
  hex = hex.replace("#", "");

  // Validate hex length
  if (hex.length !== 6) {
    throw new Error("Invalid hex color code");
  }

  // Convert the hex value to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
}

/**
 * Converts a hex color code to an RGB string.
 * @param hex - The hex color code.
 * @returns The RGB color string.
 */
export function hexToRgb(hex: string): string {
  const { r, g, b } = hexToRgbObj(hex);

  return `rgb(${r}, ${g}, ${b})`;
}


