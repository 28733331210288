import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
} from "@firebase/firestore";

import { db } from "../config/firebase"; // Adjust this import to your Firebase config file
import { Recipe } from "../models/Recipe"; // Import your Recipe type
import { prefixCollection } from "../utils/prefix";

export const fetchRecipes = async (tenant: string): Promise<Recipe[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("recipes", tenant))
    );
    const fetchedRecipes: Recipe[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as Recipe)
    );
    return fetchedRecipes;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const fetchRecipe = async (
  id: string,
  tenant: string
): Promise<Recipe | null> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("recipes", tenant))
    );
    const fetchedRecipe = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id } as Recipe))
      .find((recipe) => recipe.id === id);
    return fetchedRecipe ?? null;
  } catch (error) {
    console.error("Error fetching recipe:", error);
    throw new Error("Failed to fetch recipe");
  }
};

export const subscribeToRecipes = (
  onUpdate: (recipes: Recipe[]) => void,
  tenant: string
) => {
  const collectionRef = collection(db, prefixCollection("recipes", tenant));

  const unsubscribe = onSnapshot(
    collectionRef,
    (querySnapshot) => {
      const fetchedRecipes: Recipe[] = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as Recipe)
      );
      onUpdate(fetchedRecipes);
    },
    (error) => {
      console.error("Error fetching recipes:", error);
    }
  );

  return unsubscribe;
};

export const deleteRecipe = async (id: string, tenant: string) => {
  try {
    const itemRef = doc(db, prefixCollection("recipes", tenant), id);
    await deleteDoc(itemRef);
  } catch (error) {
    console.error("Error deleting recipe:", error);
    throw new Error("Failed to delete recipe");
  }
};

export const getRecipeCount = async (tenant: string): Promise<number> => {
  const querySnapshot = await getDocs(
    collection(db, prefixCollection("recipes", tenant))
  );
  return querySnapshot.size;
};
