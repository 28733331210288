import { useEffect, useState } from "react";
import { v4 } from "uuid";
import {
  Box,
  Button,
  Cards,
  ExpandableSection,
  Header,
  Input,
  Link,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import iSpindel from "../assets/images/ispindel.png";
import {
  countByFilter,
  deleteAllByFilter,
  fetchItemsDistinctBy,
  fetchItemsWithFilter,
  Filter,
  getCount,
  updateItem,
} from "../api/generic";
import { useAuthContext } from "../contexts/AuthContext";
import Tenant from "../models/Tenant";
import { prefixCollection } from "../utils/prefix";
import { HydroPiSample, iSpindelSample } from "../components/modules/HydroPi";

const HydroPi = () => {
  const { tenant } = useAuthContext();
  const [apiKey, setApiKey] = useState<string>();
  const [tenantObj, setTenantObj] = useState<Tenant>();
  const [loading, setLoading] = useState(true);

  const [iSpindelCount, setISpindelCount] = useState<number>();
  const [tiltCount, setTiltCount] = useState<number>();

  const [distinctTilts, setDistinctTilts] = useState<HydroPiSample[]>();
  const [distinctISpindel, setDistinctISpindel] = useState<iSpindelSample[]>();

  const handleSaveInTenant = async () => {
    if (tenantObj) {
      setLoading(true);
      tenantObj.hydroPi = apiKey;
      await updateItem<Tenant>("tenant", tenantObj.id, tenantObj);
      setLoading(false);
    }
  };

  const fetchTenant = async () => {
    const tenantObject = (
      await fetchItemsWithFilter<Tenant>("tenant", [
        {
          field: "name",
          operator: "==",
          value: tenant,
        },
      ])
    )[0];

    setTenantObj(tenantObject);
    setLoading(false);
  };

  const fetchISpindelsCount = async () => {
    const iSpidelsReadingsCount = await getCount(
      prefixCollection("ispindel", tenant, "-")
    );
    setISpindelCount(iSpidelsReadingsCount);
  };

  const fetchTiltCount = async () => {
    const tiltReadingsCount = await getCount(
      prefixCollection("hydroPi", tenant, "-")
    );
    setTiltCount(tiltReadingsCount);
  };

  const fetchTilts = async () => {
    const distinctItems = await fetchItemsDistinctBy<HydroPiSample>(
      prefixCollection("hydroPi", tenant, "-"),
      "color"
    );

    setDistinctTilts(distinctItems);
  };

  const fetchISpindels = async () => {
    const distinctItems = await fetchItemsDistinctBy<iSpindelSample>(
      prefixCollection("ispindel", tenant, "-"),
      "name"
    );
    setDistinctISpindel(distinctItems);
  };

  useEffect(() => {
    fetchTenant();
    fetchISpindelsCount();
    fetchTiltCount();
    fetchTilts();
    fetchISpindels();
  }, []);

  const hendleDeleteISpindel = async (color: string) => {
    const filters: Filter<iSpindelSample>[] = [
      { field: "color", operator: "==", value: color },
    ];

    const count = await countByFilter(
      prefixCollection("ispindel", tenant, "-"),
      filters
    );

    if (confirm(`Delete ${count} samples from ${color}`)) {
      await deleteAllByFilter(
        prefixCollection("ispindel", tenant, "-"),
        filters
      );
      setDistinctISpindel(distinctISpindel?.filter((x) => x.color !== color));
      fetchISpindelsCount();
    }
  };

  return (
    <Box padding={{ vertical: "l", horizontal: "m" }}>
      <SpaceBetween size="l">
        <Header variant="h1">Generate API Key</Header>
        <Box>
          Click the button below to generate a new API key for your application.
          Can be used for iSpindel and Tilt. (Ask for Float integration if
          needed. Just havn't gotten a hold of one yet.)
        </Box>
        <Button onClick={() => setApiKey(v4())} variant="primary">
          {"Generate API Key"}
        </Button>
        {apiKey && (
          <Box>
            <Header variant="h3">Generated API Key - HydroPi</Header>
            <SpaceBetween size="m">
              <Input value={apiKey} readOnly ariaLabel="Generated API Key" />
              <Button onClick={handleSaveInTenant} variant="normal">
                {loading ? <Spinner /> : "Use as HydroPi API Key"}
              </Button>
            </SpaceBetween>
          </Box>
        )}
        <hr />
        <ExpandableSection headerText="iSpindel HydroPi Configuration Guide">
          <SpaceBetween size="m">
            <Link href="https://github.com/henskjold73/hydropi/blob/main/docs/iSpindel.md">
              iSpindel HydroPi Configuration Guide
            </Link>
            <img src={iSpindel} />
          </SpaceBetween>
        </ExpandableSection>
        <ExpandableSection
          headerText={`iSpindels connected ${iSpindelCount ? `- [ ${iSpindelCount} samples | ${distinctISpindel?.length ?? 0} iSpindel(s) ]` : ""}`}
        >
          <SpaceBetween size="m">
            <Cards
              items={distinctISpindel ?? []}
              cardDefinition={{
                header: (item) => (
                  <Header
                    actions={
                      <Button
                        variant="icon"
                        iconName="remove"
                        onClick={() => hendleDeleteISpindel(item.color)}
                      />
                    }
                  >
                    {item.name}
                  </Header>
                ),
                sections: [
                  {
                    id: "temperature",
                    header: "Temperature",
                    content: (item) => item.temperature,
                  },
                  {
                    id: "gravity",
                    header: "Gravity",
                    content: (item) => item.gravity,
                  },
                  {
                    id: "angle",
                    header: "Angle",
                    content: (item) => item.angle,
                  },
                ],
              }}
            />
          </SpaceBetween>
        </ExpandableSection>
        <ExpandableSection
          headerText={`Tilts connected ${tiltCount ? `- [ ${tiltCount} samples | ${distinctTilts?.length ?? 0} tilt(s) ]` : ""}`}
        >
          <SpaceBetween size="m">
            <Cards
              items={distinctTilts ?? []}
              cardDefinition={{
                header: (item) => <Header>{item.color}</Header>,
                sections: [
                  {
                    id: "temperature",
                    header: "Temperature",
                    content: (item) => item.avg_temp_c,
                  },
                  {
                    id: "gravity",
                    header: "Gravity",
                    content: (item) => item.avg_gravity,
                  },
                ],
              }}
            />
          </SpaceBetween>
        </ExpandableSection>
      </SpaceBetween>
    </Box>
  );
};

export default HydroPi;
