import { FormField, Input, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { QRCode } from "react-qrcode-logo";
import UploadImage from "../components/UploadImage";

const QR = () => {
  const [value, setValue] = useState<string>(
    "https://shop.horriblemeadery.com"
  );
  const [bgColor, setBgColor] = useState<ColorResult>();
  const [fgColor, setFgColor] = useState<ColorResult>();

  const [image, setImage] = useState<string>();
  const [sizeImgW, setSizeImgW] = useState<string>("200");
  const [sizeImgH, setSizeImgH] = useState<string>("200");

  return (
    <SpaceBetween size="l">
      <SpaceBetween size="l">
        <FormField label="QR value">
          <Input
            value={value}
            onChange={({ detail }) => setValue(detail.value)}
          />
        </FormField>
        <SpaceBetween direction="horizontal" size="m">
          <FormField label="Background color">
            <SketchPicker
              color={bgColor?.hex}
              onChange={(color) => setBgColor(color)}
            />
          </FormField>
          <FormField label="Foreground color">
            <SketchPicker
              color={fgColor?.hex}
              onChange={(color) => setFgColor(color)}
            />
          </FormField>
        </SpaceBetween>
        <SpaceBetween size="m" direction="horizontal">
          <UploadImage
            title="QR image"
            fileName="qr-img"
            filePath={"NA"}
            noUpload
            setLocal={setImage}
          />
          <SpaceBetween size="m" direction="vertical">
            <FormField label="Width">
              <Input
                value={sizeImgW}
                onChange={({ detail }) => setSizeImgW(detail.value)}
              />
            </FormField>
            <FormField label="Height">
              <Input
                value={sizeImgH}
                onChange={({ detail }) => setSizeImgH(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </SpaceBetween>
      </SpaceBetween>
      <QRCode
        value={value}
        bgColor={bgColor?.hex}
        fgColor={fgColor?.hex}
        logoImage={image}
        logoWidth={parseInt(sizeImgW)}
        logoHeight={parseInt(sizeImgH)}
        size={400}
      />
    </SpaceBetween>
  );
};

export default QR;
