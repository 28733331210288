import { initializeApp } from "@firebase/app";
import { OAuthProvider, getAuth } from "@firebase/auth";
import { getStorage } from "@firebase/storage";
import { getAnalytics } from "@firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getMessaging, onMessage, getToken } from "@firebase/messaging";
import { showNotification } from "../utils/notify";

declare const process: {
  env: {
    REACT_APP_API_KEY: string;
    REACT_APP_AUTH_DOMAIN: string;
    REACT_APP_PROJECT_ID: string;
    REACT_APP_STORAGE_BUCKET: string;
    REACT_APP_MSG_SENDER_ID: string;
    REACT_APP_APP_ID: string;
    REACT_APP_MEASUREMENT_ID: string;
    REACT_APP_VAPID_KEY: string;
  };
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const microsoftProvider = new OAuthProvider("microsoft.com");
export const messaging = getMessaging(app);

// Function to register the service worker and retrieve the FCM token
export const registerServiceWorkerAndGetToken = async (): Promise<string | null> => {
  try {
    if (!("serviceWorker" in navigator)) {
      console.warn("Service Worker not supported in this browser.");
      return null;
    }

    const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
      type: "module",
    });

    // console.log("Service Worker registered with scope:", registration.scope);

    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    if (token) {
      // console.log("FCM Token:", token);
      // Optionally, send the token to your backend or save it to Firestore here
      return token;
    } else {
      console.warn("No FCM token available. Request permission to generate one.");
      return null;
    }
  } catch (error) {
    console.error("Error registering Service Worker or retrieving FCM token:", error);
    return null;
  }
};

// Handle incoming messages when the app is in the foreground
onMessage(messaging, (payload) => {
  // console.log("Foreground message received:", payload);

  if (payload.notification) {
    const { title, body } = payload.notification;

    // Use the new showNotification function
    showNotification(
      title || "Notification",
      body || "You have a new message!",
      "info" 
    );
  }
});

export default app;

