import "./index.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { requestNotificationPermission } from "./utils/notificationPermission";
import { registerServiceWorkerAndGetToken } from "./config/firebase";

requestNotificationPermission().then((granted) => {
  if (granted) {
    // console.log("Notifications are enabled.");
  } else {
    // console.log("Notifications are not enabled.");
  }
});

registerServiceWorkerAndGetToken().then((token) => {
  if (token) {
    // console.log("FCM Token retrieved:", token);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
