import React from "react";

import { Badge, Link, Table } from "@cloudscape-design/components";
import { Timestamp } from "@firebase/firestore";

import { Batch } from "../models/Batch";
import { Step } from "../models/Recipe";

interface NextStepsWidgetProps {
  batches: Batch[];
}

const NextStepsWidget: React.FC<NextStepsWidgetProps> = ({ batches }) => {
  // Function to calculate the expected end date of a step based on its start date and duration
  const calculateEndDate = (startDate: Date, duration: number): Date => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + duration); // Add duration to the start date
    return endDate;
  };

  // Function to get the most recent previous step's date
  const getPreviousStepDate = (
    recipeSteps: Step[],
    currentIndex: number
  ): Date | null => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (recipeSteps[i]?.start) {
        return recipeSteps[i].start ?? null;
      }
    }
    return null; // If no previous date is found
  };

  // Filter batches that are "in-progress" or "planned"
  const relevantBatches = batches.filter(
    (batch) => batch.status === "in-progress" || batch.status === "planned"
  );

  // Get today's date and yesterday's date
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to midnight
  const cutoff = new Date(today);
  cutoff.setDate(today.getDate() - 10); // Get yesterday's date

  // Get the next step for each relevant batch
  const nextSteps = relevantBatches.flatMap((batch) => {
    const currentStepIndex = batch.currentStepIndex;
    const nextStepIndex = currentStepIndex + 1;
    const recipeSteps = batch.recipe?.steps || [];

    // Get the current step and next step
    const currentStep = recipeSteps[currentStepIndex];
    const nextStep = recipeSteps[nextStepIndex];

    let expectedStartDate: Date | null = null;

    // If the next step has a start date, use it
    if (nextStep?.start instanceof Timestamp) {
      expectedStartDate = nextStep.start.toDate();
    }
    // If no start date for the next step, calculate it based on the current step's end date (using duration)
    else if (currentStep?.start instanceof Timestamp && currentStep?.duration) {
      const currentStepStartDate = currentStep.start.toDate();
      expectedStartDate = calculateEndDate(
        currentStepStartDate,
        currentStep.duration
      );
    }
    // If no current step start date, fallback to previous step with a date
    else {
      expectedStartDate = getPreviousStepDate(recipeSteps, currentStepIndex);
    }

    // If there's no expected start date, return nothing for this batch
    if (!expectedStartDate) return [];

    // Ensure the expected start date is after yesterday's date
    if (expectedStartDate <= cutoff) return [];

    const isOverdue = expectedStartDate < today;

    return [
      {
        batchName: batch.name,
        stepName: nextStep?.name || "Step not found",
        startDate: expectedStartDate.toDateString(), // Convert the expected start date to string format
        stepDate: expectedStartDate, // Keep the Date object for sorting
        batchId: batch.id, // To link to the batch
        stepDescription: nextStep?.description || "No description",
        isOverdue,
      },
    ];
  });

  // Sort next steps by start date
  const sortedNextSteps = nextSteps.sort((a, b) => {
    const dateA = new Date(a.stepDate).getTime();
    const dateB = new Date(b.stepDate).getTime();
    return dateA - dateB;
  });

  // Take only the next 5 steps
  const topNextSteps = sortedNextSteps.slice(0, 5);

  return (
    <Table
      variant="borderless"
      columnDefinitions={[
        {
          id: "startDate",
          header: "Expected Start Date",
          cell: (item) => (
            <Badge color={item.isOverdue ? "red" : "blue"}>
              {item.startDate}
            </Badge>
          ),
        },
        {
          id: "batchName",
          header: "Batch Name",
          cell: (item) => (
            <Link href={`/batches/${item.batchId}`}>{item.batchName}</Link>
          ),
        },
        {
          id: "stepName",
          header: "Next Step",
          cell: (item) => item.stepName,
        },
      ]}
      items={topNextSteps}
      empty="No upcoming steps"
    />
  );
};

export default NextStepsWidget;
