import { Box, ContentLayout, Header } from "@cloudscape-design/components";
import React, { FC, useEffect } from "react";
import { showNotification } from "../utils/notify";

const NotApproved: FC = () => {
  const [showMsg, setShowMsg] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMsg(true);
    }, 3000);
  }, []);

  return (
    <ContentLayout
      header={
        <>
          {showMsg && (
            <>
              <Header>Account Awaiting Approval</Header>
              <Box variant="awsui-key-label" margin={{ top: "xl" }}>
                Your account has been created but is pending approval from an
                administrator. info@horriblemeadery.com .Please check back later
                or contact support if you have any questions.
              </Box>
            </>
          )}
        </>
      }
    ></ContentLayout>
  );
};

export const Denied = () => {
  const [showMsg, setShowMsg] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      showNotification(
        "Account Denied",
        "Your account has been denied. Please contact support (Mr. Sheen) if you have any questions."
      );
      setShowMsg(true);
    }, 3000);
  }, []);

  return (
    <>
      {showMsg && (
        <ContentLayout
          header={
            <Header description="Your account has been denied. Please contact support (Mr. Sheen) if you have any questions.">
              Account Denied
            </Header>
          }
        >
          <Box variant="awsui-key-label">
            Your account has been denied. Please contact support if you have any
            questions.
          </Box>
        </ContentLayout>
      )}
    </>
  );
};

export default NotApproved;
