export interface Change {
    title: string;
    description: string;
    note?: string;
  }
  
  export const changes: Change[] = [
    // {
    //   title: "HydroPi chart for iSpindel added",
    //   description: "A new chart has been integrated to display data from the iSpindel sensor, improving monitoring capabilities.",
    //   note: "Ensure the iSpindel is calibrated correctly for accurate readings."
    // }
  ];
  
  