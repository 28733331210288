import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  DatePicker,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";

import { createItem, updateItem } from "../api/generic";
import { useAuthContext } from "../contexts/AuthContext";
import { Recipe } from "../models/Recipe";
import { GravitySample } from "../models/Sampling";
import { prefixCollection } from "../utils/prefix";

interface IGravitySampleProps {
  recipe: Recipe;
  step: number;
}

const GravitySampleModal: FC<IGravitySampleProps> = ({ recipe, step }) => {
  const { userObject, tenant } = useAuthContext();
  const { id, s } = useParams();
  const navigate = useNavigate();

  const initalGravitySample: GravitySample = {
    id: "",
    batchId: id ?? "",
    date: new Date(),
    og: "1.100",
    sg: "",
    fg: "1.000",
    temperature: 20,
    comment: "",
    step: 0,

    user: userObject?.name ?? "",
  };

  // state for each field
  const [og, setOg] = React.useState(initalGravitySample.og);
  const [sg, setSg] = React.useState(initalGravitySample.sg);
  const [fg, setFg] = React.useState(initalGravitySample.fg);
  const [temperature, setTemperature] = React.useState(
    initalGravitySample.temperature.toString()
  );
  const [comment, setComment] = React.useState(initalGravitySample.comment);
  const [date, setDate] = React.useState("");

  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (recipe.steps[0]?.gravity)
      setOg(recipe.steps[0].gravity?.toFixed(3) ?? initalGravitySample.og);
    if (recipe.steps[recipe.steps.length - 1]?.gravity)
      setFg(
        recipe.steps[recipe.steps.length - 1].gravity?.toFixed(3) ??
          initalGravitySample.fg
      );
  }, [recipe]);

  useEffect(() => {
    if (s) {
      // if (sample) {
      //   setOg(sample.og);
      //   setSg(sample.sg);
      //   setFg(sample.fg);
      //   setTemperature(sample.temperature.toString());
      //   setComment(sample.comment);
      //   setDate(sample.date.toISOString());
      // }
    } else {
      setValidationErrors({});
    }
  }, [s, recipe]);

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (date === "") {
      errors.date = "Date is required";
    }

    if (og === "") {
      errors.og = "OG is required";
    }

    if (sg === "") {
      errors.sg = "SG is required";
    }

    if (fg === "") {
      errors.fg = "FG is required";
    }

    if (temperature === "") {
      errors.temperature = "Temperature is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    const newGravitySample: GravitySample = {
      id: s ?? "",
      batchId: id ?? "",
      date: new Date(date),
      og: og,
      sg: sg,
      fg: fg,
      temperature: parseFloat(temperature),
      comment: comment,
      step,

      user: userObject?.name ?? "",
    };

    if (validateForm()) {
      if (s) {
        updateItem<GravitySample>(
          prefixCollection("gravity", tenant),
          newGravitySample.id,
          newGravitySample
        );
      } else {
        createItem<GravitySample>(
          prefixCollection("gravity", tenant),
          newGravitySample
        );
      }
      navigate("/batches/" + id);
    }
  };

  return (
    <div>
      <Form>
        <SpaceBetween size="m">
          <FormField label="Date">
            <DatePicker
              invalid={!!validationErrors.date}
              value={date}
              onChange={({ detail }) => {
                setDate(detail.value);
              }}
            />
          </FormField>
          <FormField label="OG">
            <Input
              invalid={!!validationErrors.og}
              value={og}
              onChange={({ detail }) => setOg(detail.value)}
            />
          </FormField>
          <FormField label="SG">
            <Input
              invalid={!!validationErrors.sg}
              autoFocus
              value={sg}
              onChange={({ detail }) => setSg(detail.value)}
            />
          </FormField>
          <FormField label="FG">
            <Input
              invalid={!!validationErrors.fg}
              value={fg}
              onChange={({ detail }) => setFg(detail.value)}
            />
          </FormField>
          <FormField label="Temperature">
            <Input
              invalid={!!validationErrors.temperature}
              value={temperature}
              onChange={({ detail }) => setTemperature(detail.value)}
            />
          </FormField>
          <FormField label="Comment">
            <Textarea
              value={comment}
              onChange={({ detail }) => setComment(detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
      <div style={{ float: "right", paddingTop: 20 }}>
        <SpaceBetween size="m" direction="horizontal">
          <Button onClick={() => navigate("/batches/" + id)}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>
            {s ? "Update" : "Add"}
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default GravitySampleModal;
