import { FC, useState } from "react";
import { Batch } from "../models/Batch";
import { Column, Row } from "./Flex";
import {
  Box,
  Button,
  DatePicker,
  FormField,
  Input,
} from "@cloudscape-design/components";
import { updateBatch } from "../api/batches";
import { useAuthContext } from "../contexts/AuthContext";

interface IExciseDutyModalProps {
  batch: Batch | null;
  onClose: () => void;
}

const ExciseDutyModal: FC<IExciseDutyModalProps> = ({ batch, onClose }) => {
  const { tenant } = useAuthContext();

  const [exciseDutyAmount, setExciseDutyAmount] = useState<number>(
    batch?.exciseDuty?.exciseDutyAmount ?? 0
  );
  const [exciseDutyPaid, setExciseDutyPaid] = useState<string>();

  const [exciseDutyPaymentReferenceId, setExciseDutyPaymentReferenceId] =
    useState<string>(batch?.exciseDuty?.exciseDutyPaymentReferenceId ?? "");

  const handleSave = async () => {
    if (!batch) return;

    // Save the excise duty information
    const batchToSave: Batch = {
      ...batch,
      exciseDuty: {
        exciseDutyAmount,
        ...(exciseDutyPaid && { exciseDutyPaid: new Date(exciseDutyPaid) }),
        exciseDutyPaymentReferenceId: exciseDutyPaymentReferenceId ?? "",
      },
    };

    await updateBatch(batch.id, batchToSave, tenant);
    onClose();
  };

  return (
    <Column>
      <Box variant="awsui-key-label">{`Please enter information about the excise duty for batch ${batch?.batchId}`}</Box>
      <FormField
        label="Amount paid"
        description="Please enter the amount paid for excise duty"
      >
        <Input
          type="number"
          value={exciseDutyAmount?.toString() ?? "0"}
          onChange={({ detail }) => setExciseDutyAmount(Number(detail.value))}
        />
      </FormField>
      <FormField
        label="Date paid"
        description="Please enter the date when the excise duty was paid. Empty if not paid yet"
      >
        <DatePicker
          value={exciseDutyPaid ?? new Date().toISOString()}
          onChange={({ detail }) => setExciseDutyPaid(detail.value)}
        />
      </FormField>
      <FormField
        label="Payment reference ID"
        description="Please enter the payment reference ID"
      >
        <Input
          value={exciseDutyPaymentReferenceId}
          onChange={({ detail }) =>
            setExciseDutyPaymentReferenceId(detail.value)
          }
        />
      </FormField>
      <Row align="end">
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Row>
    </Column>
  );
};

export default ExciseDutyModal;
