import React from "react";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from "recharts";
import transformTasteSamplesToRadarData, {
  RadarData,
  stageColors,
} from "../utils/taste";
import { Batch } from "../models/Batch";

const TasteRadarChart = ({ data }: { data: RadarData[] }) => (
  <RadarChart
    cx={300}
    cy={250}
    outerRadius={150}
    width={600}
    height={500}
    data={data}
  >
    <PolarGrid />
    <PolarAngleAxis dataKey="category" />
    <PolarRadiusAxis angle={30} domain={[0, 5]} />
    {Object.entries(stageColors).map(([stage, color]) => (
      <Radar
        key={stage}
        name={stage}
        dataKey={stage}
        stroke={color}
        fill={color}
        fillOpacity={0.6} // Adjust opacity for better readability
      />
    ))}
    <Legend />
    <Tooltip />
  </RadarChart>
);

const TasteVisualization = ({ batch }: { batch: Batch }) => {
  const radarData = transformTasteSamplesToRadarData(batch?.tasteSamples ?? []);

  return (
    <div>
      {(batch?.tasteSamples ?? []).length > 0 ? (
        <TasteRadarChart data={radarData} />
      ) : (
        <p>No taste samples available.</p>
      )}
    </div>
  );
};

export default TasteVisualization;
