import React, { useEffect, useState } from "react";

import {
  Button,
  FormField,
  Input,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";
import { FirebaseApp, initializeApp } from "@firebase/app";
import {
  collection,
  doc,
  Firestore,
  getDocs,
  getFirestore,
  QuerySnapshot,
  setDoc,
} from "@firebase/firestore";

import { db } from "../config/firebase";
import { CSEvent } from "../types";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

export const DataImport = () => {
  const [sourceApp, setSourceApp] = useState<FirebaseApp | null>(null);
  const [sourceDb, setSourceDb] = useState<Firestore | null>(null);
  const [collections, setCollections] = useState<string[]>([]);
  const [tenantPrefix, setTenantPrefix] = useState<string>("");

  const initConfig = {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  };

  const [sourceConfig, setSourceConfig] = useState<FirebaseConfig>(initConfig);

  const [envVariables, setEnvVariables] = useState<string>("");
  const [isConfigValid, setIsConfigValid] = useState<boolean>(false);

  const initializeSourceFirebase = () => {
    const app = initializeApp(sourceConfig, "source");

    setSourceApp(app);
    setSourceDb(getFirestore(app));

    // Manually set the collections
    setCollections([
      "batches",
      "gravity",
      "inventory",
      "menuItems",
      "recipes",
      "settings",
      "shopping",
      "stocks",
      "suggestions",
      "tenant",
      "users",
    ]);
  };

  const handleTenantPrefixChange = (event: CSEvent) => {
    const { value } = event.detail as BaseChangeDetail;
    setTenantPrefix(value);
  };

  const importData = async (
    sourceCollectionName: string,
    targetCollectionName?: string
  ) => {
    if (!sourceDb) {
      console.error("Source Firestore is not initialized");
      return;
    }

    // Assuming you have a target Firestore instance configured
    const targetCollection = targetCollectionName || sourceCollectionName;

    try {
      const sourceCollectionRef = collection(sourceDb, sourceCollectionName);

      const snapshot: QuerySnapshot = await getDocs(sourceCollectionRef);

      snapshot.forEach(async (document) => {
        const targetDocRef = doc(db, targetCollection, document.id);
        await setDoc(targetDocRef, document.data());
      });
    } catch (error) {
      console.error("Error importing data: ", error);
    }
  };

  const handleChange = (event: CSEvent, name: string) => {
    const { value } = event.detail as BaseChangeDetail;
    setSourceConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const parseEnvVariables = () => {
    const lines = envVariables.split("\n");
    const config: Partial<FirebaseConfig> = {};

    lines.forEach((line) => {
      const [key, value] = line.split("=");
      switch (key) {
        case "REACT_APP_API_KEY":
          config.apiKey = value;
          break;
        case "REACT_APP_AUTH_DOMAIN":
          config.authDomain = value;
          break;
        case "REACT_APP_PROJECT_ID":
          config.projectId = value;
          break;
        case "REACT_APP_STORAGE_BUCKET":
          config.storageBucket = value;
          break;
        case "REACT_APP_MSG_SENDER_ID":
          config.messagingSenderId = value;
          break;
        case "REACT_APP_APP_ID":
          config.appId = value;
          break;
        case "REACT_APP_MEASUREMENT_ID":
          config.measurementId = value;
          break;
        default:
          break;
      }
    });

    setSourceConfig((prevConfig) => ({ ...prevConfig, ...config }));
  };

  const handleEnvChange = (event: CSEvent) => {
    const { value } = event.detail as BaseChangeDetail;
    setEnvVariables(value);
    setSourceConfig(initConfig);
  };

  useEffect(() => {
    const {
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
    } = sourceConfig;
    setIsConfigValid(
      !!apiKey &&
        !!authDomain &&
        !!projectId &&
        !!storageBucket &&
        !!messagingSenderId &&
        !!appId
    );
  }, [sourceConfig]);

  return (
    <SpaceBetween size="m" direction="vertical">
      {!sourceApp && (
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Environment Variables">
            <Textarea
              value={envVariables}
              onChange={(e) => handleEnvChange(e)}
              placeholder="Paste your environment variables here"
            />
          </FormField>
          <Button onClick={parseEnvVariables} variant="primary">
            Parse Environment Variables
          </Button>

          <FormField label="API Key">
            <Input
              name="apiKey"
              value={sourceConfig.apiKey}
              onChange={(e) => handleChange(e, "apiKey")}
              placeholder="Enter API Key"
            />
          </FormField>
          <FormField label="Auth Domain">
            <Input
              name="authDomain"
              value={sourceConfig.authDomain}
              onChange={(e) => handleChange(e, "authDomain")}
              placeholder="Enter Auth Domain"
            />
          </FormField>
          <FormField label="Project ID">
            <Input
              name="projectId"
              value={sourceConfig.projectId}
              onChange={(e) => handleChange(e, "projectId")}
              placeholder="Enter Project ID"
            />
          </FormField>
          <FormField label="Storage Bucket">
            <Input
              name="storageBucket"
              value={sourceConfig.storageBucket}
              onChange={(e) => handleChange(e, "storageBucket")}
              placeholder="Enter Storage Bucket"
            />
          </FormField>
          <FormField label="Messaging Sender ID">
            <Input
              name="messagingSenderId"
              value={sourceConfig.messagingSenderId}
              onChange={(e) => handleChange(e, "messagingSenderId")}
              placeholder="Enter Messaging Sender ID"
            />
          </FormField>
          <FormField label="App ID">
            <Input
              name="appId"
              value={sourceConfig.appId}
              onChange={(e) => handleChange(e, "appId")}
              placeholder="Enter App ID"
            />
          </FormField>
          <FormField label="Measurement ID (optional)">
            <Input
              name="measurementId"
              value={sourceConfig.measurementId ?? ""}
              onChange={(e) => handleChange(e, "measurementId")}
              placeholder="Enter Measurement ID"
            />
          </FormField>
          <Button
            onClick={initializeSourceFirebase}
            variant="primary"
            disabled={!isConfigValid}
          >
            Initialize Source Firebase
          </Button>
        </SpaceBetween>
      )}

      {sourceApp && (
        <SpaceBetween direction="vertical" size="l">
          <Button
            iconName="angle-left"
            onClick={() => setSourceApp(null)}
            variant="primary"
            disabled={!isConfigValid}
          >
            Back
          </Button>
          <FormField label="Tenant Prefix">
            <Input
              value={tenantPrefix}
              onChange={(e) => handleTenantPrefixChange(e)}
              placeholder="Enter Tenant Prefix"
            />
          </FormField>
          <h3>Collections</h3>
          <SpaceBetween size="l" direction="vertical">
            {collections.map((collection) => (
              <SpaceBetween size="m" direction="horizontal" key={collection}>
                <Button
                  onClick={() => importData(collection)}
                  variant="primary"
                >
                  Import {collection}
                </Button>

                {tenantPrefix && (
                  <Button
                    onClick={() =>
                      importData(collection, `${tenantPrefix}_${collection}`)
                    }
                    variant="primary"
                  >
                    Import as {tenantPrefix}_{collection}
                  </Button>
                )}
              </SpaceBetween>
            ))}
          </SpaceBetween>
        </SpaceBetween>
      )}
    </SpaceBetween>
  );
};
