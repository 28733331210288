import { useEffect, useMemo } from "react";

import { subscribeToChanges } from "../api/generic";

export type Subscribable<T> = T & { id?: string };

const useSubscribe = <T extends { id?: string }>(
  collectionName: string,
  onUpdate: (items: T[]) => void,
  onError: (error: Error) => void,
  sortFunction?: (a: T, b: T) => number, // Optional sort function
  filterFunction?: (item: T) => boolean // Filter
): void => {
  const memoizedSortFunction = useMemo(() => sortFunction, [sortFunction]);
  const memoizedFilterFunction = useMemo(
    () => filterFunction,
    [filterFunction]
  );

  useEffect(() => {
    // Subscribe to changes in the specified collection or a specific item
    const unsubscribe = subscribeToChanges<T>(
      collectionName,
      (items) => {
        if (memoizedFilterFunction) {
          // Filter items using the provided filter function
          const filteredItems = items.filter(memoizedFilterFunction);
          if (memoizedSortFunction) {
            filteredItems.sort(memoizedSortFunction);
          }
          (onUpdate as (items: T[]) => void)(filteredItems);
        } else {
          if (memoizedSortFunction) {
            items.sort(memoizedSortFunction);
          }
          (onUpdate as (items: T[]) => void)(items);
        }
      },
      onError
    );

    // Cleanup function to unsubscribe from changes when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [collectionName, memoizedSortFunction]);
};
export default useSubscribe;
