import React, { useState } from "react";
import {
  Button,
  FormField,
  Input,
  Modal,
  Select,
  Slider,
  SpaceBetween,
  DatePicker,
  Textarea,
} from "@cloudscape-design/components";
import { TasteSample, TasteStage } from "../models/Sampling";
import { useAuthContext } from "../contexts/AuthContext";

type TasteSampleModalProps = {
  visible: boolean;
  onClose: () => void;
  onSave: (sample: Omit<TasteSample, "id" | "batchId">) => void;
};

const TasteStages: TasteStage[] = [
  "Must",
  "Primary",
  "Secondary",
  "Bottling",
  "Complete",
];

const TasteSampleModal: React.FC<TasteSampleModalProps> = ({
  visible,
  onClose,
  onSave,
}) => {
  const { userObject } = useAuthContext();
  const [stage, setStage] = useState<TasteStage>("Must");
  const [name] = useState<string>("");
  const [sweetness, setSweetness] = useState<number>(250);
  const [acidity, setAcidity] = useState<number>(250);
  const [body, setBody] = useState<number>(250);
  const [aftertaste, setAftertaste] = useState<number>(250);
  const [overallRating, setOverallRating] = useState<number>(250);
  const [comment, setComment] = useState<string>("");
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [user, setUser] = useState<string>(userObject?.name ?? "");
  const [date, setDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  const handleSave = () => {
    onSave({
      stage,
      name,
      sweetness,
      acidity,
      body,
      aftertaste,
      overallRating,
      date: new Date(date),
      comment,
      additionalNotes,
      user,
    });
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onClose}
      header="Add Taste Sample"
      footer={
        <SpaceBetween size="s" direction="horizontal">
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="m">
        {/* <FormField label="Name">
          <Input
            value={name}
            onChange={({ detail }) => setName(detail.value)}
          />
        </FormField> */}
        <FormField label="Stage">
          <Select
            selectedOption={{ label: stage, value: stage }}
            onChange={({ detail }) =>
              setStage(detail.selectedOption.value as TasteStage)
            }
            options={TasteStages.map((stage) => ({
              label: stage,
              value: stage,
            }))}
          />
        </FormField>

        <FormField
          label={`Sweetness (${(sweetness / 100).toFixed(2)})`}
          description="Strength of the sweetness (0 = none, 2.5 = balanced, 5 = very sweet)"
        >
          <Slider
            value={sweetness}
            onChange={({ detail }) => setSweetness(detail.value)}
            min={0}
            max={500}
            step={25}
            valueFormatter={(value) => (value / 100).toFixed(2)}
          />
        </FormField>
        <FormField
          label={`Acidity (${(acidity / 100).toFixed(2)})`}
          description="Strength of the acidity (0 = none, 2.5 = balanced, 5 = very acidic)"
        >
          <Slider
            value={acidity}
            onChange={({ detail }) => setAcidity(detail.value)}
            min={0}
            max={500}
            step={25}
            valueFormatter={(value) => (value / 100).toFixed(2)}
          />
        </FormField>
        <FormField
          label={`Body (${(body / 100).toFixed(2)})`}
          description="Strength of the body or mouthfeel (0 = thin, 2.5 = moderate, 5 = full-bodied)"
        >
          <Slider
            value={body}
            onChange={({ detail }) => setBody(detail.value)}
            min={0}
            max={500}
            step={25}
            valueFormatter={(value) => (value / 100).toFixed(2)}
          />
        </FormField>
        <FormField
          label={`Aftertaste (${(aftertaste / 100).toFixed(2)})`}
          description="Strength of the aftertaste (0 = none, 2.5 = balanced, 5 = strong lingering taste)"
        >
          <Slider
            value={aftertaste}
            onChange={({ detail }) => setAftertaste(detail.value)}
            min={0}
            max={500}
            step={25}
            valueFormatter={(value) => (value / 100).toFixed(2)}
          />
        </FormField>
        <FormField
          label={`Overall Rating (${(overallRating / 100).toFixed(2)})`}
          description="Your overall opinion of the sample (0 = poor, 2.5 = average, 5 = excellent)"
        >
          <Slider
            value={overallRating}
            onChange={({ detail }) => setOverallRating(detail.value)}
            min={0}
            max={500}
            step={25}
            valueFormatter={(value) => (value / 100).toFixed(2)}
          />
        </FormField>
        <FormField label="Date">
          <DatePicker
            value={date}
            onChange={({ detail }) => {
              setDate(detail.value);
            }}
          />
        </FormField>
        <FormField label="Comment">
          <Input
            value={comment}
            onChange={({ detail }) => setComment(detail.value)}
          />
        </FormField>
        <FormField label="Additional Notes">
          <Textarea
            value={additionalNotes}
            onChange={({ detail }) => setAdditionalNotes(detail.value)}
          />
        </FormField>
        <FormField label="User">
          <Input
            value={user}
            onChange={({ detail }) => setUser(detail.value)}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

export default TasteSampleModal;
