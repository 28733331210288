import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useNavigate, useParams } from "react-router-dom";
import { useWindowSize } from "react-use";

import {
  Autosuggest,
  Box,
  Button,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";

import { createItem, updateItem } from "../api/generic";
import DisplayImage from "../components/DisplayImage";
import ErrorBar from "../components/ErrorBar";
import UploadImage from "../components/UploadImage";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import InventoryItem from "../models/InventoryItem";
import { Recipe, Step } from "../models/Recipe";
import { prefixCollection } from "../utils/prefix";
import { sortName } from "../utils/sort";

export const initalRecipe: Recipe = {
  id: "",
  name: "",
  ingredients: [],
  steps: [],
  style: "",
  batchSize: 0,
};

interface StepWithId extends Step {
  id: string;
}

const RecipePage = () => {
  const { tenant } = useAuthContext();
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const { id } = useParams();
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState<InventoryItem[]>([]);
  const [error, setError] = useState<Error>();
  const [item, setItem] = useState<Recipe>();
  const [validate, setValidate] = useState<boolean>(false);

  const [batchSize, setBatchSize] = useState<string>("");

  const [visibleI, setVisibleI] = useState(false);
  const [editI, setEditI] = useState(-1);
  const [addIngredient, setAddIngredient] = useState("");
  const [addQuantity, setAddQuantity] = useState("");

  const [editStep, setEditStep] = useState<number>(-1);

  const [visibleS, setVisibleS] = useState(false);
  const [addStepName, setAddStepName] = useState("");
  const [addStepDescription, setAddStepDescription] = useState("");
  const [addStepDuration, setAddStepDuration] = useState("");
  const [addStepTemperature, setAddStepTemperature] = useState("");
  const [addStepGravity, setAddStepGravity] = useState("");

  const [recipes, setRecipes] = useState<Recipe[]>();
  const [steps, setSteps] = useState<StepWithId[]>();

  const [stepQ, setStepQ] = useState("");
  const [stepQItems, setStepQItems] =
    useState<{ value: string; label: string; type: string }[]>();

  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    setRecipes,
    setError,
    sortName
  );
  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    (items) => setItem(items[0]),
    setError,
    sortName,
    (item) => item.id === id
  );
  useSubscribe<InventoryItem>(
    prefixCollection("inventory", tenant),
    setIngredients,
    setError,
    sortName
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStepImport = (stepOption: any) => {
    const idParts = stepOption.value.split("#");
    const step = recipes
      ?.find((r) => r.id === idParts[0])
      ?.steps.find((s) => s.name === idParts[1]);
    if (step) {
      setItem((x) => {
        if (!x) return x;

        const { steps } = x;

        return { ...x, steps: [...steps, step] };
      });
    }
    setStepQ("");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRecipes = (stepOption: any) => {
    const recipeToAdd = recipes?.find((r) => r.id === stepOption.value);
    if (recipeToAdd && recipeToAdd.steps) {
      const stepsToAdd = recipeToAdd.steps;

      setItem((currentRecipe) => {
        if (!currentRecipe) return;
        const { steps } = currentRecipe;
        return {
          ...currentRecipe,
          steps: [...steps, ...stepsToAdd],
        };
      });
    }
    setStepQ("");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (stepOption: any) => {
    switch (stepOption.type) {
      case "step":
        handleStepImport(stepOption);
        break;
      case "recipe":
        handleRecipes(stepOption);
        break;
    }
  };

  useEffect(() => {
    if (recipes) {
      const stepsFromRecipes: StepWithId[] = recipes.flatMap((r) =>
        r.steps.map((s) => {
          return { id: `${r.id}#${s.name}`, ...s };
        })
      );

      setSteps(stepsFromRecipes);
    }
  }, [recipes]);

  useEffect(() => {
    if (steps && recipes) {
      const options = steps.map((s) => {
        return { value: s.id, label: s.name, type: "step" };
      });

      const rOptions = recipes.map((r) => {
        return { value: r.id, label: `${r.name} (Recipe)`, type: "recipe" };
      });

      setStepQItems([...options, ...rOptions]);
    }
  }, [steps]);

  useEffect(() => {
    if (!id || id === "new") return;
    else {
      setBatchSize(item?.batchSize.toString() ?? "");
    }
  }, [item]);

  const addRecipe = async (item: Omit<Recipe, "id">) => {
    await createItem<Recipe>(prefixCollection("recipes", tenant), item);
  };

  const updateRecipe = async (item: Recipe) => {
    await updateItem<Recipe>(
      prefixCollection("recipes", tenant),
      item.id,
      item
    );
  };

  const handleEditStep = (stepIndex: number) => {
    setEditStep(stepIndex);
    const step = item?.steps[stepIndex];
    if (!step) return;
    setAddStepName(step.name);
    setAddStepDescription(step.description ?? "");
    setAddStepDuration(step.duration.toString());
    setAddStepTemperature(step.temperature?.toString() ?? "");
    setAddStepGravity(step.gravity?.toString() ?? "");

    setVisibleS(true);
  };

  const submitForm = async (stay = false) => {
    setValidate(true);

    if (!item) return;
    if (item.name === "") return;
    if (item.style === "") return;

    if (id === "new") {
      await addRecipe(item);
    } else {
      updateRecipe(item);
    }

    if (!stay) navigate("/recipes");
  };

  const handleEditIngredient = (i: number) => {
    setEditI(i);

    if (i >= 0) {
      const ingredient = item?.ingredients[i];
      setAddIngredient(ingredient?.item.id ?? "");
      setAddQuantity(ingredient?.quantity?.toString() ?? "");
    }
    setVisibleI(true);
  };

  const shiftIngredientUp = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        const newIngredients = [...prevItem.ingredients];
        const [removed] = newIngredients.splice(ingredientIndex, 1);
        newIngredients.splice(ingredientIndex - 1, 0, removed);
        return {
          ...prevItem,
          ingredients: newIngredients,
        };
      }
    });
  };

  const removeStep = (stepIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        return {
          ...prevItem,
          steps: prevItem.steps.filter((_, i) => i !== stepIndex),
        };
      }
    });
  };

  const shiftIngredientDown = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        const newIngredients = [...prevItem.ingredients];
        const [removed] = newIngredients.splice(ingredientIndex, 1);
        newIngredients.splice(ingredientIndex + 1, 0, removed);
        return {
          ...prevItem,
          ingredients: newIngredients,
        };
      }
    });
  };

  const removeIngredient = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        return {
          ...prevItem,
          ingredients: prevItem.ingredients.filter(
            (_, i) => i !== ingredientIndex
          ),
        };
      }
    });
  };

  const shiftStepsUp = (stepIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        const newSteps = [...prevItem.steps];
        const [removed] = newSteps.splice(stepIndex, 1);
        newSteps.splice(stepIndex - 1, 0, removed);
        return {
          ...prevItem,
          steps: newSteps,
        };
      }
    });
  };

  const shiftStepsDown = (stepIndex: number) => {
    setItem((prevItem) => {
      if (!prevItem) {
        return {
          id: "",
          name: "",
          ingredients: [],
          steps: [],
          style: "",
          batchSize: 0,
        };
      } else {
        const newSteps = [...prevItem.steps];
        const [removed] = newSteps.splice(stepIndex, 1);
        newSteps.splice(stepIndex + 1, 0, removed);
        return {
          ...prevItem,
          steps: newSteps,
        };
      }
    });
  };

  const handleCopy = async () => {
    setValidate(true);
    if (item === null || !item) return;
    if (item?.name === "") return;
    if (item?.style === "") return;

    const newId = (
      await createItem<Recipe>(prefixCollection("recipes", tenant), {
        ...item,
        name: item.name + " (copy)",
      })
    ).id;
    if (newId) navigate("/recipes");
  };

  let day = 0;

  return (
    <ContentLayout
      disableOverlap
      header={
        <SpaceBetween direction="horizontal" size="m">
          <DisplayImage
            fileName="image"
            filePath={"recipes/" + id}
            round
            altText="Recipe image"
            style={{ height: 50, width: 50 }}
            hide={isMobile}
          />
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => navigate("print")}
                  variant="icon"
                  iconName="file"
                />
                <Button
                  onClick={() => window.history.back()}
                  formAction="none"
                  variant="normal"
                  iconName="arrow-left"
                >
                  Back
                </Button>

                <Button onClick={() => submitForm()} variant="primary">
                  Submit
                </Button>
                <Button onClick={() => submitForm(true)} variant="primary">
                  Save
                </Button>
                <Button onClick={() => handleCopy()} variant="normal">
                  Copy
                </Button>
              </SpaceBetween>
            }
            variant="h1"
            description={id === "new" ? "Create new recipe" : id}
          >
            {id === "new"
              ? "Creating new recipe"
              : (item?.name ?? "Loading...")}
          </Header>
        </SpaceBetween>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Form>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input
                invalid={validate && !item?.name}
                value={item?.name ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (!prevItem) {
                      return {
                        id: "",
                        name: newValue,
                        ingredients: [],
                        steps: [],
                        style: "",
                        batchSize: 0,
                      };
                    } else {
                      return { ...prevItem, name: newValue };
                    }
                  });
                }}
              />
            </FormField>
            <FormField label="Style">
              <Input
                invalid={validate && !item?.style}
                value={item?.style ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (!prevItem) {
                      return {
                        id: "",
                        name: "",
                        ingredients: [],
                        steps: [],
                        style: newValue,
                        batchSize: 0,
                      };
                    } else {
                      return { ...prevItem, style: newValue };
                    }
                  });
                }}
              />
            </FormField>

            <FormField label="Batch size (L)">
              <Input
                invalid={validate && !item?.batchSize}
                value={batchSize}
                onChange={(event) => {
                  const newValue = event.detail.value;
                  setBatchSize(newValue);

                  setItem((prevItem) => {
                    if (!prevItem) {
                      return {
                        id: "",
                        name: "",
                        ingredients: [],
                        steps: [],
                        style: "",
                        batchSize: parseFloat(newValue),
                      };
                    } else {
                      return { ...prevItem, batchSize: parseFloat(newValue) };
                    }
                  });
                }}
              />
            </FormField>

            <FormField label="Description">
              <Textarea
                invalid={validate && !item?.description}
                value={item?.description ?? ""}
                onChange={(event) => {
                  const newValue = event.detail.value;

                  setItem((prevItem) => {
                    if (!prevItem) {
                      return {
                        id: "",
                        name: "",
                        ingredients: [],
                        steps: [],
                        style: "",
                        batchSize: 0,
                      };
                    } else {
                      return {
                        ...prevItem,
                        description: newValue,
                      };
                    }
                  });
                }}
              />
            </FormField>

            <FormField label="Product tint color">
              <SpaceBetween direction="horizontal" size="m">
                <SketchPicker
                  color={item?.color ?? "#000000"}
                  onChange={(color) => {
                    setItem((prevItem) => {
                      if (!prevItem) {
                        return {
                          id: "",
                          name: "",
                          ingredients: [],
                          steps: [],
                          style: "",
                          batchSize: 0,
                        };
                      } else {
                        return {
                          ...prevItem,
                          color: color.hex,
                        };
                      }
                    });
                  }}
                />
                <Input
                  value={item?.color ?? "#000000"}
                  onChange={(event) => {
                    const newValue = event.detail.value;

                    setItem((prevItem) => {
                      if (!prevItem) {
                        return {
                          id: "",
                          name: "",
                          ingredients: [],
                          steps: [],
                          style: "",
                          batchSize: 0,
                        };
                      } else {
                        return {
                          ...prevItem,
                          color: newValue,
                        };
                      }
                    });
                  }}
                />
              </SpaceBetween>
            </FormField>

            <UploadImage
              key={"ri_upload"}
              title="Recipe image"
              fileName="image"
              filePath={"recipes/" + id}
              round
            />

            <UploadImage
              key={"rfl_upload"}
              title="Recipe front label"
              fileName="front"
              filePath={"recipes/" + id}
            />

            <UploadImage
              key={"rbl_upload"}
              title="Recipe back label"
              fileName="back"
              filePath={"recipes/" + id}
            />

            <UploadImage
              key={"pf_upload"}
              title="Product foreground"
              fileName="foreground"
              filePath={"product/" + id}
            />

            <UploadImage
              key={"pb_upload"}
              title="Product background"
              fileName="background"
              filePath={"product/" + id}
            />

            <div style={{ marginTop: 10 }}>
              <SpaceBetween size="m">
                <Button
                  onClick={() => {
                    setVisibleI(true);
                    setEditI(-1);
                    setAddIngredient("");
                    setAddQuantity("");
                  }}
                >
                  Add ingredients
                </Button>
                {item?.ingredients.map((ingredient, i) => (
                  <SpaceBetween
                    key={ingredient.item.id + "-" + i}
                    size="m"
                    direction="horizontal"
                  >
                    <Button
                      iconName="angle-up"
                      disabled={i === 0}
                      onClick={() => shiftIngredientUp(i)}
                      variant="inline-link"
                    />

                    <Button
                      disabled={i === item.ingredients.length - 1}
                      iconName="angle-down"
                      onClick={() => shiftIngredientDown(i)}
                      variant="inline-link"
                    />

                    <span>
                      {ingredient.item.name} {ingredient.quantity}{" "}
                      {ingredient.item.unit}
                    </span>
                    <Button
                      onClick={() => handleEditIngredient(i)}
                      variant="inline-link"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => removeIngredient(i)}
                      variant="inline-link"
                    >
                      Remove
                    </Button>
                  </SpaceBetween>
                ))}
              </SpaceBetween>
            </div>

            <div style={{ display: "flex", gap: 5 }}>
              <Button wrapText={false} onClick={() => setVisibleS(true)}>
                Add step
              </Button>
              <Autosuggest
                placeholder="Import step"
                value={stepQ}
                onChange={({ detail }) => setStepQ(detail.value)}
                options={stepQ ? stepQItems : []}
                onSelect={({ detail }) => handleSelect(detail.selectedOption)}
              />
            </div>

            {item?.steps.map((step, i) => {
              day = i === 0 ? step.duration : step.duration + day;
              return (
                <SpaceBetween key={i} size="m" direction="horizontal">
                  <Button
                    iconName="angle-up"
                    disabled={i === 0}
                    onClick={() => shiftStepsUp(i)}
                    variant="inline-link"
                  />

                  <Button
                    disabled={i === item.steps.length - 1}
                    iconName="angle-down"
                    onClick={() => shiftStepsDown(i)}
                    variant="inline-link"
                  />

                  <span>
                    Day {day - step.duration} - {step.name} - {step.duration}{" "}
                    days - {step.temperature}°C - {step.gravity} SG -{" "}
                    {step.description}
                  </span>
                  <Button
                    onClick={() => handleEditStep(i)}
                    variant="inline-link"
                  >
                    Edit
                  </Button>
                  <Button onClick={() => removeStep(i)} variant="inline-link">
                    Remove
                  </Button>
                </SpaceBetween>
              );
            })}
          </SpaceBetween>
        </Form>
        <Modal
          onDismiss={() => setVisibleI(false)}
          visible={visibleI}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    setVisibleI(false);
                    setAddIngredient("");
                    setEditI(-1);
                    setAddQuantity("");
                  }}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (item && editI >= 0) {
                      const newItem = { ...item };
                      const newIngredients = [
                        ...item.ingredients.slice(0, editI), // Include ingredients before the index
                        ...item.ingredients.slice(editI + 1), // Include ingredients after the index
                        {
                          item: ingredients.find(
                            (i) => i.id === addIngredient
                          )!,
                          quantity: parseFloat(addQuantity),
                        },
                      ];
                      // Assuming you have some code to update `item.ingredients` or to do something with `newIngredients`
                      newItem.ingredients = newIngredients;
                      setItem(newItem);
                      setVisibleI(false);
                      setAddIngredient("");
                      setAddQuantity("");
                    } else if (addIngredient && addQuantity) {
                      setItem((prevItem) => {
                        if (!prevItem) {
                          return {
                            id: "",
                            name: "",
                            ingredients: [
                              {
                                item: ingredients.find(
                                  (i) => i.id === addIngredient
                                )!,
                                quantity: parseFloat(addQuantity),
                              },
                            ],
                            steps: [],
                            style: "",
                            batchSize: 0,
                          };
                        } else {
                          return {
                            ...prevItem,
                            ingredients: [
                              ...prevItem.ingredients,
                              {
                                item: ingredients.find(
                                  (i) => i.id === addIngredient
                                )!,
                                quantity: parseFloat(addQuantity),
                              },
                            ],
                          };
                        }
                      });
                      setVisibleI(false);
                      setAddIngredient("");
                      setAddQuantity("");
                    }
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add ingredients"
        >
          <SpaceBetween direction="vertical" size="l">
            <Autosuggest
              onChange={({ detail }) => setAddIngredient(detail.value)}
              value={addIngredient}
              options={ingredients.map((ingredient) => ({
                value: ingredient.id,
                label: ingredient.name,
              }))}
              ariaLabel="Autosuggest example with values and labels"
              placeholder="Enter value"
              empty="No matches found"
            />
            <Input
              placeholder="Quantity"
              value={addQuantity}
              onChange={({ detail }) => setAddQuantity(detail.value)}
            />
            {addIngredient && (
              <strong style={{ fontSize: 18 }}>
                {parseFloat(addQuantity)}
                {ingredients.find((i) => i.id === addIngredient)?.unit} of{" "}
                {ingredients.find((i) => i.id === addIngredient)?.name}
              </strong>
            )}
          </SpaceBetween>
        </Modal>
        <Modal
          visible={visibleS}
          onDismiss={() => setVisibleS(false)}
          header="Add step"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="m">
                <Button
                  onClick={() => {
                    setVisibleS(false);
                    setAddStepName("");
                    setAddStepDescription("");
                    setAddStepDuration("");
                    setAddStepTemperature("");
                    setAddStepGravity("");
                    setEditStep(-1);
                  }}
                  variant="normal"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setItem((prevItem) => {
                      if (!prevItem) {
                        return {
                          id: "",
                          name: "",
                          ingredients: [],
                          steps: [
                            {
                              name: addStepName,
                              description: addStepDescription,
                              duration: parseInt(addStepDuration),
                              temperature: parseFloat(addStepTemperature),
                              gravity: parseFloat(addStepGravity),
                            },
                          ],
                          style: "",
                          batchSize: 0,
                        };
                      } else {
                        return {
                          ...prevItem,
                          steps:
                            editStep === -1
                              ? [
                                  ...prevItem.steps,
                                  {
                                    name: addStepName,
                                    description: addStepDescription,
                                    duration: parseInt(addStepDuration),
                                    temperature: parseInt(addStepTemperature),
                                    gravity: parseFloat(addStepGravity),
                                  },
                                ]
                              : [
                                  ...prevItem.steps.slice(0, editStep),
                                  {
                                    name: addStepName,
                                    description: addStepDescription,
                                    duration: parseInt(addStepDuration),
                                    temperature: parseInt(addStepTemperature),
                                    gravity: parseFloat(addStepGravity),
                                  },
                                  ...prevItem.steps.slice(editStep + 1),
                                ],
                        };
                      }
                    });
                    setVisibleS(false);
                    setAddStepName("");
                    setAddStepDescription("");
                    setAddStepDuration("");
                    setAddStepTemperature("");
                    setAddStepGravity("");
                    setEditStep(-1);
                  }}
                >
                  Add
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input
                placeholder="Name"
                value={addStepName}
                onChange={({ detail }) => setAddStepName(detail.value)}
              />
            </FormField>
            <FormField label="Description">
              <Textarea
                placeholder="Description"
                value={addStepDescription}
                onChange={({ detail }) => setAddStepDescription(detail.value)}
              />
            </FormField>
            <FormField label="Duration">
              <Input
                placeholder="Duration"
                value={addStepDuration}
                onChange={({ detail }) => setAddStepDuration(detail.value)}
              />
            </FormField>
            <FormField label="Temperature">
              <Input
                placeholder="Temperature"
                value={addStepTemperature}
                onChange={({ detail }) => setAddStepTemperature(detail.value)}
              />
            </FormField>
            <FormField label="Gravity">
              <Input
                placeholder="Gravity"
                value={addStepGravity}
                onChange={({ detail }) => setAddStepGravity(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Modal>
      </div>
    </ContentLayout>
  );
};

export default RecipePage;
