import { useState } from "react";

import {
  Button,
  Cards,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";

import { createItem } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import UploadImage from "../components/UploadImage";
import useSubscribe from "../hooks/useSubscribe";
import Tenant from "../models/Tenant";
import { sortName } from "../utils/sort";

export const toPascalCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Handle camelCase input
    .replace(/[^a-zA-Z0-9 ]+/g, " ") // Remove non-alphanumeric characters
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
};

export const toCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase());
};

const Tenants = () => {
  const [items, setItems] = useState<Tenant[]>([]);
  const [error, setError] = useState<Error>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [tenantName, setTenantName] = useState("");

  useSubscribe<Tenant>("tenant", setItems, setError, sortName);

  const handleCreateTenant = async () => {
    try {
      await createItem<Tenant>("tenant", {
        name: toCamelCase(tenantName),
        displayName: tenantName,
      });
      setIsModalVisible(false);
      setTenantName("");
    } catch (e) {
      setError(e as Error);
    }
  };

  return (
    <SpaceBetween size="l">
      <ErrorBar error={error} setError={setError} />
      <Button onClick={() => setIsModalVisible(true)}>Create New Tenant</Button>
      <Cards
        cardsPerRow={[{ cards: 2 }]}
        items={items}
        cardDefinition={{
          header: (item) => <Header variant="h2">{item.displayName}</Header>,
          sections: [
            {
              id: "type",
              content: (item) => (
                <div style={{ marginTop: 16 }}>
                  <strong>Id:</strong> {item.id}
                </div>
              ),
            },
            {
              id: "name",
              content: (item) => (
                <div>
                  <strong>Token:</strong> {item.name}
                </div>
              ),
            },
            {
              id: "logo",
              content: (item) => (
                <div>
                  <UploadImage
                    key={"ri_upload"}
                    fileName="image"
                    filePath={"logos/" + item.name}
                  />
                </div>
              ),
            },
          ],
        }}
      />
      <Modal
        visible={isModalVisible}
        header="Create New Tenant"
        footer={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateTenant}>
              Create
            </Button>
          </SpaceBetween>
        }
        onDismiss={() => setIsModalVisible(false)}
      >
        <FormField label="Tenant Name">
          {toCamelCase(tenantName)}
          <Input
            value={tenantName}
            onChange={(event) => setTenantName(event.detail.value)}
          />
        </FormField>
      </Modal>
    </SpaceBetween>
  );
};

export default Tenants;
