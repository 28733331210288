export const requestNotificationPermission = async () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
    return false;
  }

  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    // console.log("Notification permission granted.");
    return true;
  } else if (permission === "denied") {
    // console.warn("Notification permission denied.");
    return false;
  } else {
    // console.warn("Notification permission was dismissed or not yet granted.");
    return false;
  }
};

export const isNotificationPermissionGranted = () => {
  return Notification.permission === "granted";
};
