import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
} from "@firebase/firestore";

import { db } from "../config/firebase";
import { Batch, BatchStatus } from "../models/Batch";
import { prefixCollection } from "../utils/prefix";
import { toDate } from "../utils/timestamp";

/**
 * Archives the specified batches by updating their status to "archived".
 *
 * @param batchIds - An array of batch IDs to be archived.
 * @param tenant - The tenant identifier for the Firestore collection prefix.
 * @returns A Promise<void> that resolves when the batches are archived.
 */
export const archiveBatches = async (
  batchIds: string[],
  tenant: string
): Promise<void> => {
  try {
    const batchPromises = batchIds.map((batchId) => {
      const docRef = doc(db, prefixCollection("batches", tenant), batchId);
      return updateDoc(docRef, { status: "archived" });
    });

    // Wait for all updates to complete
    await Promise.all(batchPromises);
  } catch (error) {
    console.error("Error archiving batches:", error);
    throw new Error("Failed to archive batches");
  }
};

const generateBatchId = async (
  sDate: Date | Timestamp,
  tenant: string
): Promise<string> => {
  const startDate = toDate(sDate);
  // Extract the year (last two digits)
  const year = String(startDate.getFullYear()).slice(-2);

  // Query batches that have the same year
  const startOfYear = new Date(startDate.getFullYear(), 0, 1);
  const endOfYear = new Date(startDate.getFullYear() + 1, 0, 1);

  const batchesCollection = collection(db, prefixCollection("batches", tenant));
  const yearQuery = query(
    batchesCollection,
    where("startDate", ">=", startOfYear),
    where("startDate", "<", endOfYear)
  );
  const querySnapshot = await getDocs(yearQuery);

  // Find the highest batch increment
  let highestIncrement = 0;
  querySnapshot.docs.forEach((doc) => {
    const batch = doc.data();
    if (batch.batchId) {
      const [, increment] = batch.batchId.split("_").map(Number);
      if (!isNaN(increment) && increment > highestIncrement) {
        highestIncrement = increment;
      }
    }
  });

  // Generate a new batch ID by adding one to the highest increment
  const newIncrement = highestIncrement + 1;
  return `${year}_${newIncrement}`;
};

export const createBatch = async (
  newBatch: Omit<Batch, "id" | "batchId">,
  tenant: string
): Promise<Batch> => {
  try {
    // Generate batchId if not included
    const batchId = await generateBatchId(newBatch.startDate, tenant);

    // Add new batch to Firestore
    const docRef = await addDoc(
      collection(db, prefixCollection("batches", tenant)),
      {
        ...newBatch,
        batchId, // Include the generated batchId
      }
    );

    return { ...newBatch, id: docRef.id, batchId };
  } catch (error) {
    console.error("Error creating batch:", error);
    throw new Error("Failed to create batch");
  }
};

export const batchFromName = (name: string) => {
  let parsedBatchId: string | null = null;
  const regex = /\b(\d{2}_\d+)\b/; // Matches a pattern like "24_3"

  const match = name.match(regex);
  if (match) {
    parsedBatchId = match[1];
  }

  return parsedBatchId;
};

export const updateBatch = async (
  id: string,
  updatedFields: Partial<Batch>,
  tenant: string
): Promise<void> => {
  try {
    const docRef = doc(db, prefixCollection("batches", tenant), id);

    // Fetch the current batch data
    const batchDoc = await getDoc(docRef);
    if (!batchDoc.exists()) {
      throw new Error("Batch not found");
    }

    const existingBatch = batchDoc.data() as Batch;

    // Generate batchId only if missing
    const batchId =
      existingBatch.batchId ||
      batchFromName(existingBatch.name) ||
      (await generateBatchId(existingBatch.startDate, tenant));

    // Update the batch in Firestore with new fields and possibly generated batchId
    await updateDoc(docRef, {
      ...updatedFields,
      batchId,
    });
  } catch (error) {
    console.error("Error updating batch:", error);
    throw new Error("Failed to update batch");
  }
};

export const fetchBatch = async (
  id: string,
  tenant: string
): Promise<Batch | null> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("batches", tenant))
    );
    const fetchedBatch = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }) as Batch)
      .find((batch) => batch.id === id);
    return fetchedBatch ?? null;
  } catch (error) {
    console.error("Error fetching batch:", error);
    throw new Error("Failed to fetch batch");
  }
};

export const fetchBatches = async (
  tenant: string,
  sortField: keyof Batch = "batchId",
  ascending: boolean = true
): Promise<Batch[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("batches", tenant))
    );

    // Convert Firestore documents to the Batch type
    const fetchedBatches: Batch[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        }) as Batch
    );

    // const ab = fetchedBatches.map(async (x) => {
    //   return { a: x.batchId, b: await generateBatchId(x.startDate) };
    // });
    // const p = await Promise.all(ab);

    // Sorting logic for `batchId`
    if (sortField === "batchId") {
      fetchedBatches.sort((a, b) => {
        const parseBatchId = (batchId: string) => {
          const [yearStr, incrementStr] = batchId.split("_");
          return {
            year: parseInt(yearStr, 10),
            increment: parseInt(incrementStr, 10),
          };
        };

        const batchA = parseBatchId(a.batchId ?? "00_0");
        const batchB = parseBatchId(b.batchId ?? "00_0");

        // Compare by year first, then increment
        let comparison = batchA.year - batchB.year;
        if (comparison === 0) {
          comparison = batchA.increment - batchB.increment;
        }

        return ascending ? comparison : -comparison;
      });
    } else {
      // General sorting logic for other fields
      fetchedBatches.sort((a, b) => {
        let comparison = 0;
        const valueA = a[sortField];
        const valueB = b[sortField];

        // Handle date comparison, ensuring both values are `Date` or `Timestamp`
        if (
          (valueA instanceof Date || valueA instanceof Timestamp) &&
          (valueB instanceof Date || valueB instanceof Timestamp)
        ) {
          const dateA = toDate(valueA).getTime();
          const dateB = toDate(valueB).getTime();
          comparison = dateA - dateB;
        } else if (typeof valueA === "string") {
          // Compare strings
          comparison = valueA.localeCompare(valueB as string);
        } else if (typeof valueA === "number") {
          // Compare numbers
          comparison = valueA - (valueB as number);
        }

        // Reverse the comparison for descending order
        return ascending ? comparison : -comparison;
      });
    }

    return fetchedBatches;
  } catch (error) {
    console.error("Error fetching batches:", error);
    throw new Error("Failed to fetch batches");
  }
};

export const fetchBatchesByStatus = async (
  status: string,
  tenant: string
): Promise<Batch[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("batches", tenant))
    );
    const fetchedRecipes: Batch[] = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }) as Batch)
      .filter((batch) => batch.status === status);
    const sortedByStartDate = fetchedRecipes.sort(
      (a, b) => toDate(a.startDate).getTime() - toDate(b.startDate).getTime()
    );
    return sortedByStartDate;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const subscribeToBatchesByStatus = (
  status: BatchStatus[],
  tenant: string,
  onUpdate: (batches: Batch[]) => void
) => {
  const batchesCollectionRef = collection(
    db,
    prefixCollection("batches", tenant)
  );

  const batchesQuery = query(
    batchesCollectionRef,
    where("status", "in", status)
  );

  const unsubscribe = onSnapshot(
    batchesQuery,
    (querySnapshot) => {
      const fetchedBatches: Batch[] = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate?.toDate(),
          }) as Batch
      );
      onUpdate(fetchedBatches);
    },
    (error) => {
      console.error("Error fetching active batches:", error);
    }
  );

  return unsubscribe;
};

export const subscribeToBatches = (
  onUpdate: (batches: Batch[]) => void,
  tenant: string
) => {
  const batchesCollectionRef = collection(
    db,
    prefixCollection("batches", tenant)
  ); // Replace 'batches' with your actual collection name

  const unsubscribe = onSnapshot(
    batchesCollectionRef,
    (querySnapshot) => {
      const fetchedBatches: Batch[] = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate?.toDate(),
          }) as Batch
      );
      onUpdate(fetchedBatches);
    },
    (error) => {
      console.error("Error fetching batches:", error);
    }
  );

  return unsubscribe;
};

export const getBatchCount = async (tenant: string): Promise<number> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, prefixCollection("batches", tenant))
    );
    return querySnapshot.size;
  } catch (error) {
    console.error("Error fetching batch count:", error);
    throw new Error("Failed to fetch batch count");
  }
};
