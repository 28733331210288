import { RecipeIngredient } from "../models/Recipe";

interface IIngredientsListProps {
  ingredients: RecipeIngredient[];
}
export const IngredientsList = ({ ingredients }: IIngredientsListProps) => {
  return (
    <>
      <h2>Ingredients</h2>
      <ul className="ingredients">
        {ingredients.map((ingredient, index) => (
          <li key={index}>
            <label>
              <input type="checkbox" />
              {ingredient.quantity}
              {ingredient.item.unit} of {ingredient.item.name}
            </label>
          </li>
        ))}
      </ul>
    </>
  );
};
