import { TasteSample } from "../models/Sampling";

export type RadarData = {
  category: string; // e.g., "Sweetness"
  key: keyof TasteSample; // Ensure `key` matches `TasteSample` keys
  [stage: string]: number | string; // Dynamic keys for stages
};

export const stageColors = {
  Must: "#FFD700", // Light Gold
  Primary: "#FFA500", // Light Orange
  Secondary: "#87CEEB", // Light Blue
  Bottling: "#90EE90", // Light Green
  Complete: "#FFB6C1", // Light Pink
};

const transformTasteSamplesToRadarData = (
  tasteSamples: TasteSample[]
): RadarData[] => {
  const radarData: RadarData[] = [
    { category: "Sweetness", key: "sweetness" },
    { category: "Acidity", key: "acidity" },
    { category: "Body", key: "body" },
    { category: "Aftertaste", key: "aftertaste" },
    { category: "Overall Rating", key: "overallRating" },
  ];

  // Group taste samples by stage
  const stageGroups: Record<string, TasteSample[]> = tasteSamples.reduce(
    (acc, sample) => {
      acc[sample.stage] = acc[sample.stage] || [];
      acc[sample.stage].push(sample);
      return acc;
    },
    {} as Record<string, TasteSample[]>
  );

  // Populate radarData with average values per stage
  Object.entries(stageGroups).forEach(([stage, samples]) => {
    radarData.forEach((data) => {
      const categoryKey = data.key;

      // Calculate the average for the category
      const total = samples.reduce(
        (sum, sample) => sum + (sample[categoryKey] as number),
        0
      );
      const average = total / samples.length;

      // Assign the average to the radar data for the stage
      data[stage] = average / 100; // Convert scale to 0–5
    });
  });

  return radarData;
};

export default transformTasteSamplesToRadarData;
