export type Bottle = {
  id: string;         // The unique identifier for the bottle
  name: string;       // The name of the bottle
  description: string; // Optional description (can be empty string)
  volume: number;     // The volume in liters
  price: number;      // The price of the bottle
};

export type BottleData = {
  bottles: Bottle[]; // Array of Bottle objects
};

const Data: BottleData = {
  bottles: [
    {
      id: "1",
      name: "Bottle 0.75L",
      description: "",
      volume: 0.75,
      price: 1.94,
    },
    {
      id: "2",
      name: "Bottle 0.33L",
      description: "",
      volume: 0.33,
      price: 1.42,
    },
  ],
};

export default Data;
