import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button,
  Header,
  Icon,
  SpaceBetween,
} from "@cloudscape-design/components";

import {
  deleteFileFromStorage,
  fetchImageUrl,
  uploadFileToStorage,
} from "../api/storage";

interface UploadImageProps {
  title?: string;
  fileName: string;
  filePath: string;
  round?: boolean;
  noUpload?: boolean;
  setLocal?: (file?: string) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({
  title,
  fileName,
  filePath,
  round,
  noUpload,
  setLocal,
}) => {
  const [uuid, setUuid] = useState<string>(uuidv4());
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [progess, setProgress] = useState<number>(-1);
  const [error, setError] = useState<string | null>(null);
  const [url, setUrl] = useState<string>("");

  const fetchUrl = async () => {
    const url = await fetchImageUrl(filePath + "/" + fileName);

    if (url) {
      setSelectedImage(null);
      setUuid(uuidv4());
      setUrl(url);
    } else {
      setUrl("");
    }
  };

  useEffect(() => {
    fetchUrl();
  }, [fileName, filePath]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setSelectedImage(file);
    const fileUrl = URL.createObjectURL(file);
    if (setLocal) setLocal(fileUrl);
  };

  const clear = () => {
    setSelectedImage(null);
    setProgress(-1);
    setError(null);
    if (setLocal) setLocal();
  };

  const handleUploadClick = async () => {
    if (!selectedImage) return;
    if (url) {
      const confirm = window.confirm(
        "Are you sure you want to replace this image?"
      );
      if (!confirm) return;
    }

    await uploadFileToStorage(
      selectedImage,
      fileName,
      filePath,
      setProgress,
      (error) => setError(error.message),
      () => {
        fetchUrl();
        clear();
      }
    );
  };

  const handleDelete = async () => {
    if (!url) return;
    const confirm = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirm) return;
    deleteFileFromStorage(filePath + "/" + fileName);
    setUrl("");
    clear();
  };

  return (
    <div style={{ position: "relative" }}>
      {title && (
        <Header>
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="h3">{title}</Box>
            {selectedImage && (
              <Button
                key="clear"
                variant="inline-icon"
                onClick={clear}
                iconName="close"
              />
            )}
            {error && (
              <span key="error" style={{ color: "red" }}>
                <Icon name="status-warning" />
              </span>
            )}
          </SpaceBetween>
        </Header>
      )}
      {progess > -1 && (
        <progress
          value={progess}
          max="100"
          style={{ position: "absolute", width: "100%" }}
        />
      )}
      <div style={{ visibility: "hidden" }}>
        <input
          id={uuid}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
      <SpaceBetween size="m" direction="horizontal">
        {(selectedImage || url) && (
          <img
            key="image"
            style={{
              height: 200,
              width: 200,
              border: selectedImage ? "dotted 2px" : "solid 2px",
              borderRadius: round ? "50%" : 0,
              objectFit: "cover",
            }}
            src={
              selectedImage
                ? URL.createObjectURL(selectedImage)
                : url
                  ? url
                  : ""
            }
            alt="Selected"
          />
        )}
        <SpaceBetween size="m" key="buttons">
          <Button
            key="choose"
            fullWidth
            iconName="file"
            onClick={() => document.getElementById(uuid)?.click()}
          >
            Choose image
          </Button>
          {selectedImage && !noUpload && (
            <Button
              key="upload"
              fullWidth
              variant="primary"
              iconName="upload"
              onClick={handleUploadClick}
            >
              Upload image
            </Button>
          )}
          {url && (
            <Button
              key="delete"
              variant="normal"
              fullWidth
              iconName="close"
              onClick={handleDelete}
            >
              Delete image
            </Button>
          )}
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
};

export default UploadImage;
