import { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  Cards,
  ContentLayout,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";

import { createItem, deleteItem, updateItem } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import {
  PriorityLevel,
  sortSuggestions,
  Suggestion,
  SuggestionStatus,
  SuggestionType,
} from "../models/Suggestion";
import { convertNewlinesToBreaks } from "../utils/html";
import { toDate } from "../utils/timestamp";
import { useLocalStorage } from "react-use";

const getStatusBadgeColor = (
  status: SuggestionStatus
): "grey" | "blue" | "green" | "red" => {
  switch (status) {
    case SuggestionStatus.Open:
      return "grey";
    case SuggestionStatus.InProgress:
      return "blue";
    case SuggestionStatus.Resolved:
      return "green";
    case SuggestionStatus.Closed:
      return "grey";
    default:
      return "grey";
  }
};

const getPrioBadgeColor = (
  status: PriorityLevel
): "grey" | "blue" | "green" | "red" => {
  switch (status) {
    case PriorityLevel.High:
      return "red";
    case PriorityLevel.Medium:
      return "blue";
    case PriorityLevel.Low:
      return "grey";
    default:
      return "grey";
  }
};

const Suggestions = () => {
  const { currentUser, userObject } = useAuthContext();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [suggestionsFiltered, setSuggestionsFiltered] = useState<Suggestion[]>(
    []
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentSuggestionId, setCurrentSuggestionId] = useState<string | null>(
    null
  );
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [error, setError] = useState<Error>();
  const [selectedState, setSelectedState] = useLocalStorage(
    "suggestions-filter",
    "not-closed"
  );

  const dropdownItems = [
    { id: "all", text: "All" },
    { id: "not-closed", text: "Not Closed" },
    { id: "working", text: "Active" },
    ...Object.values(SuggestionStatus).map((status) => ({
      id: status.toLowerCase(),
      text: status,
    })),
  ];

  const initSuggestion: Omit<Suggestion, "id"> = {
    type: SuggestionType.FeatureRequest,
    title: "",
    description: "",
    createdAt: new Date(),
    status: SuggestionStatus.Open,
    priority: PriorityLevel.Low,
    reporter: currentUser?.displayName ?? "",
    assignedTo: "",
  };

  useSubscribe<Suggestion>(
    "suggestions",
    setSuggestions,
    setError,
    sortSuggestions
  );

  useEffect(() => {
    switch (selectedState) {
      case "all":
        setSuggestionsFiltered(suggestions);
        break;
      case "not-closed":
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) => suggestion.status !== SuggestionStatus.Closed
          )
        );
        break;
      case "working":
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) =>
              suggestion.status !== SuggestionStatus.Closed &&
              suggestion.status !== SuggestionStatus.Resolved
          )
        );
        break;
      case SuggestionStatus.Open.toLowerCase():
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) => suggestion.status === SuggestionStatus.Open
          )
        );
        break;
      case SuggestionStatus.InProgress.toLowerCase():
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) => suggestion.status === SuggestionStatus.InProgress
          )
        );
        break;
      case SuggestionStatus.Resolved.toLowerCase():
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) => suggestion.status === SuggestionStatus.Resolved
          )
        );
        break;
      case SuggestionStatus.Closed.toLowerCase():
        setSuggestionsFiltered(
          suggestions.filter(
            (suggestion) => suggestion.status === SuggestionStatus.Closed
          )
        );
        break;
      default:
        console.log("No matching state");
    }
  }, [selectedState, suggestions]);

  const [newSuggestion, setNewSuggestion] =
    useState<Omit<Suggestion, "id">>(initSuggestion);

  const handleTakeCard = (id: string) => {
    // Update the existing suggestion
    updateItem<Suggestion>("suggestions", id, {
      assignedTo: currentUser?.displayName ?? "",
      updatedAt: new Date(),
    }).catch((error) => console.error("Error updating suggestion:", error));
  };

  const handleState = (id: string, status: SuggestionStatus) => {
    // Update the existing suggestion
    updateItem<Suggestion>("suggestions", id, {
      status,
      updatedAt: new Date(),
    }).catch((error) => console.error("Error updating suggestion:", error));
  };

  const handleCreateOrUpdateSuggestion = () => {
    if (isEditMode && currentSuggestionId) {
      // Update the existing suggestion
      updateItem<Suggestion>("suggestions", currentSuggestionId, newSuggestion)
        .then(() => {
          setIsModalVisible(false);
          setIsEditMode(false);
          setCurrentSuggestionId(null);
        })
        .catch((error) => console.error("Error updating suggestion:", error));
    } else {
      // Create a new suggestion
      createItem<Suggestion>("suggestions", newSuggestion)
        .then(() => {
          setIsModalVisible(false);
        })
        .catch((error) => console.error("Error creating suggestion:", error));
    }
  };

  const openDeleteConfirm = (itemId: string) => {
    setCurrentSuggestionId(itemId);
    setIsDeleteConfirmVisible(true);
  };

  const handleDeleteSuggestion = () => {
    if (currentSuggestionId) {
      deleteItem("suggestions", currentSuggestionId)
        .then(() => {
          setIsDeleteConfirmVisible(false);
          setCurrentSuggestionId(null);
        })
        .catch((error) => console.error("Error deleting suggestion:", error));
    }
  };

  const handleEditSuggestion = (suggestion: Suggestion) => {
    setNewSuggestion({
      type: suggestion.type,
      title: suggestion.title,
      description: suggestion.description,
      createdAt: toDate(suggestion.createdAt),
      status: suggestion.status,
      reporter: suggestion.reporter,
      priority: suggestion.priority,
      assignedTo: suggestion.assignedTo,
    });
    setIsEditMode(true);
    setCurrentSuggestionId(suggestion.id);
    setIsModalVisible(true);
  };

  return (
    <ContentLayout
      disableOverlap={!suggestions}
      header={
        <Header
          variant="h1"
          description={"Tell us what you need or if something isn't working"}
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button
                onClick={() => {
                  setNewSuggestion(initSuggestion);
                  setIsModalVisible(true);
                }}
              >
                + Add new
              </Button>
              <ButtonDropdown
                items={dropdownItems}
                onItemClick={(event) => setSelectedState(event.detail.id)}
              >
                {dropdownItems.find((x) => x.id === selectedState)?.text}
              </ButtonDropdown>
            </SpaceBetween>
          }
        >
          Suggestions
        </Header>
      }
    >
      <ErrorBar error={error} setError={setError} />
      <Cards
        cardDefinition={{
          header: (item) => (
            <Header
              info={
                <SpaceBetween size="xs" direction="horizontal">
                  <Badge color={getStatusBadgeColor(item.status)}>
                    {item.status}
                  </Badge>
                  <Badge
                    color={getPrioBadgeColor(
                      item.priority ?? PriorityLevel.Low
                    )}
                  >
                    {item.priority ?? PriorityLevel.Low}
                  </Badge>
                </SpaceBetween>
              }
              variant="h2"
            >
              {item.title}
            </Header>
          ),

          sections: [
            {
              id: "description",
              content: (item) => (
                <div>
                  <strong>Description:</strong>
                  <br />
                  {convertNewlinesToBreaks(item.description)}
                </div>
              ),
            },
            {
              id: "createdAt",
              content: (item) => (
                <div>
                  <strong>Created At:</strong>{" "}
                  {toDate(item.createdAt)?.toLocaleString()}
                </div>
              ),
            },
            {
              id: "updatedAt",
              content: (item) => (
                <div style={{ marginTop: -16 }}>
                  <strong>Updated At:</strong>{" "}
                  {item.updatedAt
                    ? toDate(item.updatedAt)?.toLocaleString()
                    : toDate(item.createdAt)?.toLocaleString()}
                </div>
              ),
            },
            {
              id: "reporter",
              content: (item) => (
                <div>
                  <strong>Reporter:</strong> {item.reporter}
                </div>
              ),
            },
            {
              id: "assignedTo",
              content: (item) => (
                <div style={{ marginTop: -16 }}>
                  <strong>Assigned To:</strong>{" "}
                  {item.assignedTo || "Unassigned"}
                </div>
              ),
            },
            {
              id: "actions",
              content: (item) => (
                <SpaceBetween direction="horizontal" size="l">
                  <SpaceBetween direction="horizontal" size="xxxs">
                    {(userObject?.role === "admin" ||
                      userObject?.role === "god" ||
                      item.reporter === currentUser?.displayName) && (
                      <>
                        <Button
                          variant="icon"
                          iconName="edit"
                          onClick={() => handleEditSuggestion(item)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="icon"
                          iconName="close"
                          onClick={() => openDeleteConfirm(item.id)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {(userObject?.role === "admin" ||
                      userObject?.role === "god") && (
                      <Button
                        variant="icon"
                        iconName="user-profile-active"
                        onClick={() => handleTakeCard(item.id)}
                      >
                        Take
                      </Button>
                    )}
                  </SpaceBetween>
                  <div style={{ width: 20 }} />
                  {(userObject?.role === "admin" ||
                    userObject?.role === "god") && (
                    <SpaceBetween direction="horizontal" size="xxxs">
                      <Button
                        disabled={item.status === SuggestionStatus.Open}
                        variant="icon"
                        iconName="status-pending"
                        onClick={() =>
                          handleState(item.id, SuggestionStatus.Open)
                        }
                      >
                        Start
                      </Button>
                      <Button
                        disabled={item.status === SuggestionStatus.InProgress}
                        variant="icon"
                        iconName="caret-right-filled"
                        onClick={() =>
                          handleState(item.id, SuggestionStatus.InProgress)
                        }
                      >
                        Start
                      </Button>
                      <Button
                        disabled={item.status === SuggestionStatus.Resolved}
                        variant="icon"
                        iconName="status-positive"
                        onClick={() =>
                          handleState(item.id, SuggestionStatus.Resolved)
                        }
                      >
                        Start
                      </Button>
                      <Button
                        disabled={item.status === SuggestionStatus.Closed}
                        variant="icon"
                        iconName="status-negative"
                        onClick={() =>
                          handleState(item.id, SuggestionStatus.Closed)
                        }
                      >
                        Start
                      </Button>
                    </SpaceBetween>
                  )}
                </SpaceBetween>
              ),
            },
          ],
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 400, cards: 2 },
          { minWidth: 800, cards: 3 },
        ]}
        items={suggestionsFiltered}
        loadingText="Loading suggestions..."
        empty={
          <Box textAlign="center" color="inherit">
            <b>No suggestions</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No suggestions to display.
            </Box>
          </Box>
        }
      />
      <Modal
        visible={isDeleteConfirmVisible}
        onDismiss={() => setIsDeleteConfirmVisible(false)}
        header="Confirm Deletion"
        footer={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => setIsDeleteConfirmVisible(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteSuggestion}>
              Delete
            </Button>
          </SpaceBetween>
        }
      >
        <Box textAlign="center" color="inherit">
          Are you sure you want to delete this suggestion?
        </Box>
      </Modal>

      <Modal
        visible={isModalVisible}
        onDismiss={() => setIsModalVisible(false)}
        header="Create Suggestion"
        footer={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
            <Button variant="primary" onClick={handleCreateOrUpdateSuggestion}>
              {isEditMode ? "Update" : "Create"}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <FormField label="Type">
            <Select
              selectedOption={{
                label: newSuggestion.type,
                value: newSuggestion.type,
              }}
              onChange={({ detail }) =>
                setNewSuggestion({
                  ...newSuggestion,
                  type: detail.selectedOption.value as SuggestionType,
                })
              }
              options={[
                {
                  label: "Feature Request",
                  value: SuggestionType.FeatureRequest,
                },
                {
                  label: "Service Request",
                  value: SuggestionType.ServiceRequest,
                },
                { label: "Bug Report", value: SuggestionType.BugReport },
              ]}
            />
          </FormField>
          <FormField label="Title">
            <Input
              value={newSuggestion.title}
              onChange={({ detail }) =>
                setNewSuggestion({ ...newSuggestion, title: detail.value })
              }
            />
          </FormField>
          <FormField label="Description">
            <Textarea
              rows={10}
              value={newSuggestion.description}
              onChange={({ detail }) =>
                setNewSuggestion({
                  ...newSuggestion,
                  description: detail.value,
                })
              }
            />
          </FormField>

          <FormField label="Reporter">
            <Input
              readOnly
              value={newSuggestion.reporter}
              onChange={({ detail }) =>
                setNewSuggestion({ ...newSuggestion, reporter: detail.value })
              }
            />
          </FormField>
          <FormField label="Priority">
            <Select
              selectedOption={{
                label: newSuggestion.priority || "N/A",
                value: newSuggestion.priority || "N/A",
              }}
              onChange={({ detail }) =>
                setNewSuggestion({
                  ...newSuggestion,
                  priority: detail.selectedOption.value as PriorityLevel,
                })
              }
              options={[
                { label: "Low", value: PriorityLevel.Low },
                { label: "Medium", value: PriorityLevel.Medium },
                { label: "High", value: PriorityLevel.High },
              ]}
            />
          </FormField>
        </SpaceBetween>
      </Modal>
    </ContentLayout>
  );
};

export default Suggestions;
