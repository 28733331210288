import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "@firebase/storage";

import { storage } from "../config/firebase";

export async function uploadFileToStorage(
  file: File,
  fileName: string,
  path: string,
  onProgress: (progress: number) => void,
  onError: (error: Error) => void,
  onComplete: () => void
) {
  const storageRef = ref(storage, `/${path}/${fileName}`);

  try {
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        onError(error);
      },
      () => {
        onComplete();
      }
    );
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

export const fetchImageUrl = async (filePath: string): Promise<string> => {
  const fileRef = ref(storage, filePath);

  try {
    const url = await getDownloadURL(fileRef);
    return url;
  } catch {
    return "";
  }
};

export async function deleteFileFromStorage(path: string) {
  const storageRef = ref(storage, path);
  try {
    await deleteObject(storageRef);
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
}
