import { Timestamp } from "@firebase/firestore";

import { HydroPiSample } from "../components/modules/HydroPi";
import { GravitySample } from "../models/Sampling";
import { toDate } from "./timestamp";

export type FermentationStatus =
  | "Okay"
  | "Staled"
  | "Finished"
  | "Vigorous"
  | "Slow";

export const FermentationStatusEmojis: Record<FermentationStatus, string> = {
  Vigorous: "🔥", // Fire emoji for vigorous activity
  Slow: "🐢", // Turtle emoji for slow activity
  Staled: "⚠️", // Warning emoji for stalled fermentation
  Finished: "✅", // Checkmark emoji for finished fermentation
  Okay: "🟢", // Green Circle for "okay/active"
};

const findLastIndex = <T>(
  array: T[],
  predicate: (value: T) => boolean
): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return i;
    }
  }
  return -1;
};

interface FermentationParams {
  samples: HydroPiSample[];
  startDate: Timestamp;
  endDate?: Timestamp;
  gravityThreshold: number; // Considered "finished" if gravity is below this
  stalledThreshold: number; // Number of days with no significant gravity drop
}

const calculateAverageDropRate = (
  samples: HydroPiSample[],
  windowSize: number
): number | null => {
  if (samples.length < windowSize + 1) return null;

  // Get the most recent window of samples
  const recentSamples = samples.slice(-windowSize - 1);

  // Calculate total gravity drop and total time elapsed
  const totalDrop =
    recentSamples[0].avg_gravity -
    recentSamples[recentSamples.length - 1].avg_gravity;
  const totalTime =
    recentSamples[recentSamples.length - 1].timestamp.seconds -
    recentSamples[0].timestamp.seconds;

  if (totalTime <= 0) return null;

  return totalDrop / totalTime; // Return average drop rate per second
};

export const estimateFinishTimeFromHydroPiSamples = ({
  samples,
  targetGravity,
}: {
  samples: HydroPiSample[];
  targetGravity: number;
}): Timestamp | null => {
  if (samples.length < 2) {
    console.warn("Not enough samples to calculate finish time.");
    return null;
  }

  // Sort samples by timestamp
  const sortedSamples = [...samples].sort(
    (a, b) => a.timestamp.seconds - b.timestamp.seconds
  );

  // Calculate the average drop rate over the last few samples
  const dropRatePerSecond = calculateAverageDropRate(sortedSamples, 3);
  if (!dropRatePerSecond || dropRatePerSecond <= 0) {
    console.warn("Gravity is not dropping or average drop rate is invalid.");
    return null;
  }

  // Use the most recent sample for remaining gravity calculation
  const lastSample = sortedSamples[sortedSamples.length - 1];
  const gravityRemaining = lastSample.avg_gravity - targetGravity;

  if (gravityRemaining <= 0) {
    // console.info("Target gravity already reached.");
    return Timestamp.fromDate(new Date());
  }

  const timeRemainingSeconds = gravityRemaining / dropRatePerSecond;

  // Estimate finish time
  const finishMillis =
    lastSample.timestamp.seconds * 1000 + timeRemainingSeconds * 1000;

  return Timestamp.fromMillis(finishMillis);
};

export const checkFermentationStatus = ({
  samples,
  endDate,
  gravityThreshold,
  stalledThreshold,
}: FermentationParams): FermentationStatus => {
  if (samples.length === 0) {
    return "Staled"; // No samples mean the process isn't progressing
  }

  // Sort samples by timestamp to ensure proper sequence
  const sortedSamples = [...samples].sort(
    (a, b) => a.timestamp.seconds - b.timestamp.seconds
  );

  // Check if fermentation is "finished"
  const lastSample = sortedSamples[sortedSamples.length - 1];
  if (lastSample.avg_gravity <= gravityThreshold) {
    return "Finished";
  }

  // Analyze gravity drops
  const gravityDrops = sortedSamples.map((sample, index, arr) => {
    if (index === 0) return 0; // Skip the first sample
    return arr[index - 1].avg_gravity - sample.avg_gravity;
  });

  const currentTime = endDate?.seconds ?? Timestamp.now().seconds;

  // Check vigorous activity (significant drops > 0.005 in the last 24 hours)
  const vigorousThreshold = 0.01;
  const last24Hours = sortedSamples.filter(
    (sample) => currentTime - sample.timestamp.seconds <= 24 * 3600
  );
  const dropsLast24Hours = last24Hours.map((sample, index, arr) => {
    if (index === 0) return 0;
    return arr[index - 1].avg_gravity - sample.avg_gravity;
  });
  const totalDropLast24Hours = dropsLast24Hours.reduce((a, b) => a + b, 0);

  if (totalDropLast24Hours > vigorousThreshold) {
    return "Vigorous";
  }

  const last12Hours = sortedSamples.filter(
    (sample) => currentTime - sample.timestamp.seconds <= 12 * 3600
  );

  const dropsLast12Hours = last12Hours.map((sample, index, arr) => {
    if (index === 0) return 0;
    return arr[index - 1].avg_gravity - sample.avg_gravity;
  });

  const totalDropLast12Hours = dropsLast12Hours.reduce((a, b) => a + b, 0);

  // Check slow activity (smaller drops > 0.001 in the last 12-24 hours)
  const okayThreshold = 0.005;

  if (totalDropLast12Hours > okayThreshold) {
    return "Okay";
  }

  // Check slow activity (smaller drops > 0.001 in the last 12-24 hours)
  const slowThreshold = 0.001;

  if (totalDropLast12Hours > slowThreshold) {
    return "Slow";
  }

  // Check stalled (no significant drop for `stalledThreshold` days)
  const lastSignificantDropThreshold = 0.001;
  const lastSignificantDropIndex = findLastIndex(
    gravityDrops,
    (drop) => drop > lastSignificantDropThreshold
  );
  const lastSignificantDropDate =
    lastSignificantDropIndex !== -1
      ? sortedSamples[lastSignificantDropIndex].timestamp
      : null;

  if (
    lastSignificantDropDate &&
    currentTime - lastSignificantDropDate.seconds > stalledThreshold * 86400
  ) {
    return "Staled";
  }

  return "Slow"; // Default to slow if no other state applies
};

interface SimpleFermentationParams {
  sampleRecords: { sample: GravitySample; id: string }[];
  batchId: string;
  gravityThreshold: number; // Gravity below this is considered "finished"
  stalledThreshold: number; // Days without significant gravity drop
}

export const checkFermentationStatusSimple = ({
  sampleRecords,
  batchId,
  gravityThreshold,
  stalledThreshold,
}: SimpleFermentationParams): FermentationStatus => {
  const record = sampleRecords.find((record) => record.id === batchId);

  if (!record || !record.sample) {
    console.warn(`No sample found for batch ID: ${batchId}`);
    return "Staled"; // No samples available
  }

  const { sample } = record;

  // Safely access specific gravity
  const sg = parseFloat(sample.sg ?? "0"); // Default to 0 if sg is undefined
  if (sg <= gravityThreshold) {
    return "Finished";
  }

  // Check for stalled fermentation based on the last updated date
  const nowSeconds = Timestamp.now().seconds;
  const sampleDateSeconds = Timestamp.fromDate(toDate(sample.date)).seconds;

  if (nowSeconds - sampleDateSeconds > stalledThreshold * 86400) {
    return "Staled";
  }

  // Otherwise, fermentation is "okay"
  return "Okay";
};
