import { BoardProps } from "@cloudscape-design/board-components";

export function exportLayout(
  items: ReadonlyArray<BoardProps.Item<WidgetDataType>>
): ReadonlyArray<StoredWidgetPlacement> {
  return items.map((item) => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan,
  }));
}

export interface StoredWidgetPlacement {
  id: string;
  columnOffset?: Record<number, number>;
  rowSpan?: number;
  columnSpan?: number;
}

export interface WidgetDataType {
  title: string;
  description: string;
  disableContentPaddings?: boolean;
  provider?: React.JSXElementConstructor<{ children: React.ReactElement }>;
  header: React.JSXElementConstructor<Record<string, never>>;
  content: React.JSXElementConstructor<Record<string, never>>;
  footer?: React.JSXElementConstructor<Record<string, never>>;
  staticMinHeight?: number;
}
export type DashboardWidgetItem = BoardProps.Item<WidgetDataType>;

export type WidgetConfig = Pick<DashboardWidgetItem, "definition" | "data">;
