import { useEffect, useState } from "react";

import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import { retrieveAllInventory, SqInventoryItem } from "../api/squarespace";
import ErrorBar from "../components/ErrorBar";
import Loader from "../components/Loader";
import UpdateQuantityModal from "../components/UpdateQuantityModel";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Settings } from "../models/Settings";
import { prefixCollection } from "../utils/prefix";

const initalSettings: Settings = { id: "", integrations: [] };

const Stocks = () => {
  const { tenant } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stocks, setStocks] = useState<SqInventoryItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<SqInventoryItem>();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [error, setError] = useState<Error>();

  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (result) => setSettings(result.length > 0 ? result[0] : initalSettings),
    setError
  );

  const fetchStocks = async () => {
    setIsLoading(true);

    const ssKey = settings?.integrations.find(
      (i) => i.integrationName === "squarespace"
    );

    if (ssKey) {
      const result = await retrieveAllInventory(ssKey.apiKey);
      setStocks(result?.inventory ?? []);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (settings) fetchStocks();
  }, [settings]);

  const columnDefinitions = [
    {
      id: "variantId",
      header: "Variant ID",
      cell: (item: SqInventoryItem) => item.variantId,
    },
    {
      id: "sku",
      header: "SKU",
      cell: (item: SqInventoryItem) => item.sku,
    },
    {
      id: "descriptor",
      header: "Descriptor",
      cell: (item: SqInventoryItem) => item.descriptor,
    },
    {
      id: "isUnlimited",
      header: "Is Unlimited",
      cell: (item: SqInventoryItem) => (item.isUnlimited ? "Yes" : "No"),
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item: SqInventoryItem) => item.quantity,
    },
    {
      id: "update",
      header: "Update Quantity",
      cell: (item: SqInventoryItem) => (
        <Button
          variant="icon"
          iconName="edit"
          onClick={() => setSelectedItem(item)}
        />
      ),
    },
  ];

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          actions={
            <SpaceBetween size="m" direction="horizontal"></SpaceBetween>
          }
          variant="h1"
          description="Hint: The stock might go out of sync if it is manipulated manually"
        >
          Stock
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Loader isLoading={isLoading} size="large">
          <Table
            wrapLines
            variant="embedded"
            items={stocks}
            sortingDisabled
            columnDefinitions={columnDefinitions}
          />
        </Loader>
      </div>
      <UpdateQuantityModal
        visible={!!selectedItem}
        item={selectedItem}
        onClose={() => setSelectedItem(undefined)}
        onUpdate={fetchStocks}
      />
    </ContentLayout>
  );
};

export default Stocks;
