import "@cloudscape-design/global-styles/index.css";

import { BrowserRouter as Router } from "react-router-dom";
import { useLocalStorage, useLocation } from "react-use";

import { AppLayout } from "@cloudscape-design/components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AbvCalculator from "./components/AbvCalculator";
import Hint from "./components/Hint";
import HoneyCalculator from "./components/HoneyCalculator";
import { AuthContextProvider } from "./contexts/AuthContext";
import Approve from "./layout/Approve";
import Loginout from "./layout/Loginout";
import { PrintRouting } from "./layout/PrintRouting";
import { Routing } from "./layout/Routing";
import Sidebar from "./layout/Sidebar";
import ChangesPanel from "./components/ChangesPanel";

function App() {
  const location = useLocation();
  const [nav, setNav] = useLocalStorage("nav", true);

  const [tool, setTool] = useLocalStorage("tool", false);

  return (
    <AuthContextProvider>
      <Router>
        {location.pathname?.endsWith("/print") ? (
          <PrintRouting />
        ) : (
          <AppLayout
            navigationOpen={nav}
            onNavigationChange={({ detail }) => setNav(detail.open)}
            toolsOpen={tool}
            onToolsChange={({ detail }) => setTool(detail.open)}
            splitPanel={<ChangesPanel />}
            navigation={
              <>
                <Sidebar />
                <Approve />
                <Loginout />
              </>
            }
            content={
              <>
                <Routing />
                <ToastContainer />
              </>
            }
            tools={
              <div style={{ marginTop: 40, padding: 8 }}>
                <Hint />
                <AbvCalculator />
                <HoneyCalculator />
              </div>
            }
          />
        )}
      </Router>
    </AuthContextProvider>
  );
}

export default App;
