import { useEffect, useState } from "react";
import { v4 } from "uuid";

import {
  Box,
  Button,
  FormField,
  Input,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import { createItem, fetchItemsWithFilter, updateItem } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import InventoryView from "../components/Squarespace/InventoryView";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Integration, Settings } from "../models/Settings";
import { prefixCollection } from "../utils/prefix";
import { initalSettings } from "./Settings";
import Tenant from "../models/Tenant";

const ApiKeyTab = () => {
  const { tenant } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [newIntegrationName, setNewIntegrationName] = useState("");
  const [newApiKey, setNewApiKey] = useState("");
  const [error, setError] = useState<Error>();

  const [tenantObj, setTenantObj] = useState<Tenant>();

  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (result) => setSettings(result.length > 0 ? result[0] : initalSettings),
    setError
  );

  const handleAddIntegration = () => {
    if (newIntegrationName && newApiKey && settings) {
      const newIntegration: Integration = {
        id: v4(),
        integrationName: newIntegrationName,
        apiKey: newApiKey,
      };
      const updatedSettings = {
        ...settings,
        integrations: [...settings.integrations, newIntegration],
      };
      setSettings(updatedSettings);
      setNewIntegrationName("");
      setNewApiKey("");
    }
  };

  const fetchTenant = async () => {
    const tenantObject = (
      await fetchItemsWithFilter<Tenant>("tenant", [
        {
          field: "name",
          operator: "==",
          value: tenant,
        },
      ])
    )[0];

    setTenantObj(tenantObject);
    setLoading(false);
  };

  useEffect(() => {
    fetchTenant();
  }, []);

  const handleSaveSettings = async () => {
    if (settings) {
      setLoading(true);
      if (settings.id !== "")
        await updateItem<Settings>("settings", settings.id, settings);
      else
        await createItem<Settings>("settings", {
          integrations: settings.integrations,
        });

      setLoading(false);
    }
  };

  const handleRemoveIntegration = (id: string) => {
    if (settings) {
      const updatedSettings = {
        ...settings,
        integrations: settings.integrations.filter((i) => i.id !== id),
      };
      setSettings(updatedSettings);
    }
  };

  return (
    <SpaceBetween size="m">
      <ErrorBar error={error} setError={setError} />
      <Box>Add API keys for different integrations.</Box>
      <SpaceBetween size="m" direction="horizontal">
        <FormField label="Integration Name">
          <Input
            value={newIntegrationName}
            onChange={({ detail }) => setNewIntegrationName(detail.value)}
          />
        </FormField>
        <FormField label="API Key">
          <Input
            value={newApiKey}
            onChange={({ detail }) => setNewApiKey(detail.value)}
          />
        </FormField>
        <Button
          onClick={handleAddIntegration}
          disabled={
            !newIntegrationName ||
            !newApiKey ||
            newIntegrationName.toLowerCase() === "hydropi"
          }
        >
          Add
        </Button>
        <Button
          disabled={newIntegrationName.toLowerCase() === "hydropi"}
          onClick={handleSaveSettings}
          loading={loading}
        >
          Save
        </Button>
      </SpaceBetween>

      {settings && (
        <Table
          variant="embedded"
          items={
            tenantObj
              ? [
                  {
                    integrationName: "HydroPi",
                    apiKey: tenantObj.hydroPi,
                    id: "HydroPi",
                  },
                  ...settings.integrations,
                ]
              : settings.integrations
          }
          columnDefinitions={[
            {
              header: "Integration Name",
              cell: (item) => item.integrationName,
            },
            {
              header: "API Key",
              cell: (item) => item.apiKey,
            },
            {
              header: "",
              cell: (item) => (
                <Button
                  variant="icon"
                  iconName="close"
                  disabled={item.id === "HydroPi"}
                  onClick={() => handleRemoveIntegration(item.id)}
                />
              ),
            },
          ]}
        />
      )}
      <InventoryView
        apiKey={
          settings?.integrations.find(
            (x) => x.integrationName === "squarespace"
          )?.apiKey
        }
      />
    </SpaceBetween>
  );
};

export default ApiKeyTab;
