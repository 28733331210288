import { HydroPiSample } from "../components/modules/HydroPi";
import { Batch } from "../models/Batch";
import { GravitySample } from "../models/Sampling";
import { toDate } from "./timestamp";

export const addDays = (date: Date, daysToAdd: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
};

export const calculateDateDay = (batch: Batch, date: Date) => {
  const { startDate, recipe } = batch;
  const steps = recipe?.steps ?? [];
  const startDateObj = toDate(startDate);
  // const totalDays = steps.reduce((acc, step) => acc + step.duration, 0);
  const daysSinceStart = Math.floor(
    (date.getTime() - startDateObj.getTime()) / (1000 * 3600 * 24)
  );
  const currentDay = daysSinceStart + 1;

  let currentStepEndDate = startDateObj;

  const acc = steps.reduce((acc, step) => {
    let duration = step.duration;
    const start = step.start ? toDate(step.start) : currentStepEndDate;

    if (step.end) {
      duration = Math.floor(
        (toDate(step.end).getTime() - toDate(start).getTime()) /
          (1000 * 3600 * 24)
      );
    }

    currentStepEndDate = addDays(currentStepEndDate, duration);
    return acc + duration;
  }, 0);

  return { currentDay, totalDays: acc, progress: (currentDay / acc) * 100 };
};

export const calculateABVSimple = (og: number, sg: number) => {
  return ((og - sg) * 131).toFixed(2);
};

export const calculateABV = (sample: GravitySample) => {
  const { og, sg } = sample;
  return ((parseFloat(og) - parseFloat(sg)) * 131).toFixed(2);
};
export const calculateFinalABV = (sample: GravitySample) => {
  const { og, fg } = sample;
  return ((parseFloat(og) - parseFloat(fg)) * 131).toFixed(2);
};

export const calculateHydroPiABV = (
  sample: HydroPiSample,
  start: HydroPiSample
) => {
  return ((start.avg_gravity - sample.avg_gravity) * 131).toFixed(2);
};
export const calculateHydroPiFinalABV = (start: HydroPiSample) => {
  return ((start.avg_gravity - 1) * 131).toFixed(2);
};
