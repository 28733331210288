import { Step } from "../models/Recipe";

interface IStepListProps {
  steps: Step[];
}

const StepsList = ({ steps }: IStepListProps) => {
  return (
    <>
      <h2>Steps</h2>
      <ol className="steps">
        {steps.map((step, index) => (
          <li key={index}>
            <label>
              <input type="checkbox" />
              <strong>{step.name}</strong> - Duration: {step.duration} days
              {step.description && <p>{step.description}</p>}
              {step.temperature && <p>Temperature: {step.temperature} °F</p>}
              {step.gravity && <p>Gravity: {step.gravity} °P</p>}
              {step.pH && <p>pH: {step.pH}</p>}
              {step.notes && <p>Notes: {step.notes}</p>}
            </label>
          </li>
        ))}
      </ol>
    </>
  );
};

export default StepsList;
