import React, { FC, Fragment } from "react";

import { Flashbar } from "@cloudscape-design/components";

interface IErrorBarProps {
  error?: Error;
  setError: (error?: Error) => void;
}

const ErrorBar: FC<IErrorBarProps> = ({ error, setError }) => {
  return (
    <Fragment>
      {error && (
        <div style={{ marginBottom: 20 }}>
          <Flashbar
            items={[
              {
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setError(undefined),
                content: <>{error}</>,
                id: "message_1",
              },
            ]}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ErrorBar;
