import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { User } from "@firebase/auth";
import { doc, onSnapshot } from "@firebase/firestore";

import { updateUser } from "../api/approval";
import { auth, db, registerServiceWorkerAndGetToken } from "../config/firebase";
import { UserData } from "../models/UserData";
import { IChildrenProps } from "../types";
import { saveToken } from "../config/tokens";

interface IAuthContextState {
  currentUser: User | null;
  loading: boolean;
  userObject: UserData | null;
  tenant: string;
  handleFCMToken: (userId: string) => Promise<void>;
}

const AuthContext = createContext({} as IAuthContextState);

const initUserObject: UserData = {
  name: "",
  email: "",
  role: "user",
  approved: false,
  denied: false,
  uid: "",
  authProvider: "",
  photoURL: "",
  tenant: "trail",
};

export const handleFCMToken = async (userId: string): Promise<void> => {
  try {
    const fcmToken = await registerServiceWorkerAndGetToken();
    if (fcmToken) {
      await saveToken(userId, fcmToken);
      // console.log(`FCM token saved for user ${userId}: ${fcmToken}`);
    } else {
      // console.warn("No FCM token retrieved. Check notification permissions.");
    }
  } catch (error) {
    console.error(`Error handling FCM token for user ${userId}:`, error);
  }
};

export const AuthContextProvider: FC<IChildrenProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [userObject, setUserObject] = useState<UserData | null>(null);

  useEffect(() => {
    // add currentUser changes to userObject
    if (currentUser && userObject && !userObject.denied) {
      const newUserObject = { ...userObject };
      newUserObject.name = currentUser.displayName ?? "";
      newUserObject.email = currentUser.email ?? "";
      newUserObject.photoURL = currentUser.photoURL ?? "";
      setUserObject(newUserObject);

      // update userObject in Firestore
      updateUser(newUserObject.uid, newUserObject);
    }
  }, [currentUser]);

  useEffect(() => {
    let unsubscribeFromUserObject = () => {};

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        const userRef = doc(db, "users", user.uid);

        // Set up a real-time listener for the user's document
        unsubscribeFromUserObject = onSnapshot(
          userRef,
          async (doc) => {
            if (doc.exists()) {
              setUserObject(doc.data() as UserData);
            } else {
              // Handle case where user does not have a Firestore document
              setUserObject({
                ...initUserObject,
                name: user.displayName ?? "",
                email: user.email ?? "",
                authProvider: user.providerId,
                photoURL: user.photoURL ?? "",
                tenant: "trail",
              });
            }

            // Handle FCM token
            await handleFCMToken(user.uid);
          },
          (error) => {
            console.error("Error fetching user data:", error);
            // Handle error
          }
        );
      } else {
        setUserObject(null); // Clear user object when not logged in
      }
    });

    return () => {
      unsubscribe();
      unsubscribeFromUserObject();
    };
  }, []);

  const values = useMemo(
    () => ({
      currentUser,
      loading,
      userObject,
      tenant: userObject?.tenant ?? "trail",
      handleFCMToken,
    }),
    [currentUser, loading, userObject]
  );

  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): IAuthContextState => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      "`useAuthContext` hook must be used within a `AuthContextProvider` component"
    );
  }
  return context;
};
