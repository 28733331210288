import React, { useEffect, useState } from "react";
import { HiOutlineMinus, HiOutlinePlusSm } from "react-icons/hi";
import { RiEqualFill, RiInfinityLine } from "react-icons/ri";

import {
  Box,
  Button,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";

import {
  createInventoryAdjustmentPayload,
  OperationType,
  pushInventoryToApi,
  retrieveAllInventory,
  SqAdjustment,
  SqInventoryItem,
} from "../api/squarespace";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Settings } from "../models/Settings";
import { initalSettings } from "../pages/Settings";
import { prefixCollection } from "../utils/prefix";
import ErrorBar from "./ErrorBar";

interface UpdateQuantityModalProps {
  item?: SqInventoryItem;
  visible: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
}

const UpdateQuantityModal: React.FC<UpdateQuantityModalProps> = ({
  item,
  visible,
  onClose,
  onUpdate,
}) => {
  const { tenant } = useAuthContext();
  const [quantity, setQuantity] = useState<number>(item?.quantity || 0);
  const [operation, setOperation] = useState<OperationType | null>(null);
  const [selectedProduct, setSelectedProduct] =
    useState<SqInventoryItem | null>(null);
  const [products, setProducts] = useState<SqInventoryItem[]>([]);
  const [error, setError] = useState<Error>();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(false);

  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (result) => setSettings(result.length > 0 ? result[0] : initalSettings),
    setError
  );

  const updateProducts = () => {
    const ssKey = settings?.integrations.find(
      (i) => i.integrationName === "squarespace"
    );
    if (ssKey) {
      retrieveAllInventory(ssKey.apiKey).then((result) => {
        if (result && result.inventory) {
          setProducts(result.inventory);
        }
      });
    }
  };

  useEffect(() => {
    if (!item && visible) updateProducts();
  }, [item, visible]);

  const handleSave = async () => {
    setLoading(true);
    const targetItem = item || selectedProduct;
    const ssKey = settings?.integrations.find(
      (i) => i.integrationName === "squarespace"
    );

    if (targetItem && operation !== null) {
      const adjustments: SqAdjustment[] = [
        {
          variantId: targetItem.variantId,
          quantity,
          operation,
        },
      ];

      const payload = createInventoryAdjustmentPayload(adjustments);

      if (ssKey) await pushInventoryToApi(payload, ssKey.apiKey);
      if (onUpdate) onUpdate();
      if (onClose) onClose();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (item) {
      setQuantity(item.quantity);
    } else if (selectedProduct) {
      setQuantity(selectedProduct.quantity);
    }
  }, [item, selectedProduct]);

  return (
    <Modal
      visible={visible}
      onDismiss={onClose}
      header={`${item?.descriptor || selectedProduct?.descriptor || "Update"}`}
      footer={
        <SpaceBetween direction="horizontal" size="s">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={!operation}
            loading={loading}
            variant="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="m">
        <ErrorBar error={error} setError={setError} />
        <Box>
          Choose to either add or subtract from the stock. You can also set the
          stock or flag it as unlimited.
        </Box>
        {!item && (
          <Select
            options={products.map((product) => ({
              label: product.descriptor,
              value: product.variantId,
            }))}
            selectedOption={
              selectedProduct
                ? {
                    label: selectedProduct.descriptor,
                    value: selectedProduct.variantId,
                  }
                : null
            }
            onChange={(e) => {
              const selected = products.find(
                (p) => p.variantId === e.detail.selectedOption.value
              );
              setSelectedProduct(selected || null);
            }}
            placeholder="Select a product"
          />
        )}
        <SpaceBetween direction="horizontal" size="m">
          <Input
            type="number"
            value={quantity.toString()}
            onChange={(e) => setQuantity(parseInt(e.detail.value, 10))}
          />
          <SpaceBetween direction="horizontal" size="s">
            <div style={{ marginTop: 10 }} />
            <Button
              variant={
                operation === OperationType.Increment ? "primary" : "normal"
              }
              onClick={() => {
                setOperation(OperationType.Increment);
                setQuantity(1);
              }}
              iconSvg={<HiOutlinePlusSm />}
            />
            <Button
              variant={
                operation === OperationType.Decrement ? "primary" : "normal"
              }
              onClick={() => {
                setOperation(OperationType.Decrement);
                setQuantity(1);
              }}
              iconSvg={<HiOutlineMinus />}
            />
            <Button
              variant={
                operation === OperationType.SetFinite ? "primary" : "normal"
              }
              onClick={() => {
                setOperation(OperationType.SetFinite);
                setQuantity(item?.quantity ?? 10);
              }}
              iconSvg={<RiEqualFill />}
            />
            <Button
              variant={
                operation === OperationType.SetUnlimited ? "primary" : "normal"
              }
              onClick={() => {
                setOperation(OperationType.SetUnlimited);
                setQuantity(0);
              }}
              iconSvg={<RiInfinityLine />}
            />
          </SpaceBetween>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
};

export default UpdateQuantityModal;
