import {
  Button,
  DatePicker,
  Form,
  FormField,
  Select,
  SelectProps,
  SpaceBetween,
  TimeInput,
} from "@cloudscape-design/components";
import { FC, useEffect, useState } from "react";
import { HydroPiColor, HydroPiColors, HydroPiConfig } from "../../models/Batch";
import { fetchBatch, updateBatch } from "../../api/batches";
import { Timestamp } from "@firebase/firestore";
import { useAuthContext } from "../../contexts/AuthContext";
import { prefixCollection } from "../../utils/prefix";
import { fetchItemsDistinctBy } from "../../api/generic";
import { iSpindelSample } from "./HydroPi";

interface IHydroPiConfig {
  batchId?: string;
  onClose: () => void;
}

const HydroPiConfigModal: FC<IHydroPiConfig> = ({ batchId, onClose }) => {
  const { tenant } = useAuthContext();
  const [color, setColor] = useState<SelectProps.Option>();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [iSpindels, setISpindels] = useState<SelectProps.Option[]>([]);

  const options: SelectProps.Option[] = HydroPiColors.map((value) => ({
    label: value,
    value,
  }));

  const fetchISpindels = async () => {
    const distinctItems = await fetchItemsDistinctBy<iSpindelSample>(
      prefixCollection("ispindel", tenant, "-"),
      "name"
    );

    const toOptions: SelectProps.Option[] = distinctItems.map((iss) => {
      return {
        label: iss.name,
        value: iss.name,
      };
    });

    setISpindels(toOptions);
  };

  const fetchBatchData = async () => {
    if (!batchId) return;

    const batch = await fetchBatch(batchId, tenant);

    if (batch?.hydroPi) {
      setColor({ label: batch.hydroPi.color, value: batch.hydroPi.color });
      setStartDate(batch.hydroPi.startDate.toDate().toISOString());
      setEndDate(batch.hydroPi.endDate?.toDate().toISOString() ?? "");
    }
  };

  useEffect(() => {
    fetchISpindels();
    fetchBatchData();
  }, []);

  const handleSubmit = async () => {
    if (!batchId || !color) return;

    const hydroPi: HydroPiConfig = {
      color: color.value as HydroPiColor,
      startDate: Timestamp.fromDate(new Date(startDate)),
      type:
        iSpindels.findIndex((is) => is.value === color.value) !== -1
          ? "iSpindel"
          : "Tilt",
    };

    if (endDate) hydroPi.endDate = Timestamp.fromDate(new Date(endDate));

    await updateBatch(batchId, { hydroPi }, tenant);

    onClose();
  };

  const handleDateChange = (newDate: string, current: Date) => {
    if (newDate === "") return "";
    const [year, month, day] = newDate.split("-").map(Number);
    current.setFullYear(year, month - 1, day);
    return current?.toISOString() ?? "";
  };

  // Handle time changes from TimeInput
  const handleTimeChange = (timeString: string, current: Date) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    current.setHours(hours, minutes);
    return current?.toISOString() ?? "";
  };

  return (
    <div>
      <Form>
        <SpaceBetween size="m">
          <FormField label="Tilt color">
            <Select
              selectedOption={color ?? { label: "Choose Tilt Color" }}
              options={[...options, ...iSpindels]}
              onChange={(event) => setColor(event.detail.selectedOption)}
              placeholder="Select a color"
            />
          </FormField>
          <FormField
            label="Start date"
            description="What samples to add to this batch."
          >
            <SpaceBetween size="m" direction="horizontal">
              <DatePicker
                value={startDate.split("T")[0]}
                onChange={({ detail }) =>
                  setStartDate(
                    handleDateChange(detail.value, new Date(startDate))
                  )
                }
              />
              <TimeInput
                format="hh:mm"
                placeholder="hh:mm"
                disabled={!startDate}
                value={`${String(new Date(startDate).getHours()).padStart(2, "0")}:${String(
                  new Date(startDate).getMinutes()
                ).padStart(2, "0")}`}
                onChange={({ detail }) => {
                  setStartDate(
                    handleTimeChange(detail.value, new Date(startDate))
                  );
                }}
              />

              <Button
                variant="normal"
                onClick={() => setStartDate(new Date().toISOString())}
              >
                Now
              </Button>
            </SpaceBetween>
          </FormField>
          <FormField
            label="End date"
            description="Leave empty if it's an active batch."
          >
            <SpaceBetween size="m" direction="horizontal">
              <DatePicker
                value={endDate.split("T")[0]}
                onChange={({ detail }) =>
                  setEndDate(handleDateChange(detail.value, new Date(endDate)))
                }
              />
              <TimeInput
                format="hh:mm"
                placeholder="hh:mm"
                disabled={!endDate}
                value={`${String(new Date(endDate).getHours()).padStart(2, "0")}:${String(
                  new Date(endDate).getMinutes()
                ).padStart(2, "0")}`}
                onChange={({ detail }) => {
                  setEndDate(handleTimeChange(detail.value, new Date(endDate)));
                }}
              />
            </SpaceBetween>
          </FormField>
        </SpaceBetween>
      </Form>
      <div style={{ float: "right", paddingTop: 20 }}>
        <SpaceBetween size="m" direction="horizontal">
          <Button onClick={() => onClose()}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default HydroPiConfigModal;
