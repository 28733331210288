import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";

import { db } from "../config/firebase";
import { GravitySample } from "../models/Sampling";
import { prefixCollection } from "../utils/prefix";
import { toDate } from "../utils/timestamp";

export const createGravitySample = async (
  item: Omit<GravitySample, "id">,
  tenant: string
) => {
  try {
    const docRef = await addDoc(
      collection(db, prefixCollection("gravity", tenant)),
      item
    );
    return docRef.id;
  } catch (error) {
    console.error("Error adding inventory item:", error);
    throw error; // You might want to handle this error differently
  }
};

export const updateGravitySample = async (
  item: GravitySample,
  tenant: string
) => {
  try {
    const itemRef = doc(db, prefixCollection("gravity", tenant), item.id);
    await updateDoc(itemRef, item);
  } catch (error) {
    console.error("Error updating inventory item:", error);
    // Handle the error (e.g., show an error message to the user)
  }
};

export const getGravitySample = async (id: string, tenant: string) => {
  try {
    const itemRef = doc(db, prefixCollection("gravity", tenant), id);
    const itemSnap = await getDoc(itemRef);

    if (itemSnap.exists()) {
      return itemSnap.data();
    } else {
      // doc.data() will be undefined in this case
    }
  } catch (error) {
    console.error("Error updating inventory item:", error);
    // Handle the error (e.g., show an error message to the user)
  }
};

export const subscribeToGravitySamplesByBatchId = (
  batchId: string,
  callback: (items: GravitySample[]) => void,
  tenant: string
) => {
  const _collection = collection(db, prefixCollection("gravity", tenant));
  const q = query(_collection, where("batchId", "==", batchId));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const items: GravitySample[] = snapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as GravitySample)
    );

    // sort by toDate(date) ascending

    const sortedByDate = items.sort((a, b) => {
      return toDate(a.date).getTime() - toDate(b.date).getTime();
    });

    callback(sortedByDate);
  });
  return unsubscribe;
};

export const getLatestGravitySample = async (
  batchId: string,
  tenant: string
) => {
  try {
    const _collection = collection(db, prefixCollection("gravity", tenant));
    const q = query(_collection, where("batchId", "==", batchId));

    const querySnapshot = await getDocs(q);
    const fetchedItems: GravitySample[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as GravitySample)
    );

    const sortedByDate = fetchedItems.sort((a, b) => {
      return toDate(b.date).getTime() - toDate(a.date).getTime();
    });

    return sortedByDate[0];
  } catch (error) {
    console.error("Error fetching inventory items:", error);
    throw new Error("Failed to fetch inventory items");
  }
};
