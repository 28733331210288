import { useCallback, useEffect, useState } from "react";
import { RiGoogleFill, RiMicrosoftFill } from "react-icons/ri";

import { Spacer } from "@chakra-ui/react";
import {
  Button,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";

import { deleteItem, updateItem } from "../api/generic";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe, { Subscribable } from "../hooks/useSubscribe";
import { UserData } from "../models/UserData";
import { sortName } from "../utils/sort";
import TenantSelect from "./TenantSelect";

const UserTable = () => {
  const { userObject } = useAuthContext();
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<SelectProps.Option | null>(
    null
  );
  const [users, setUsers] = useState<UserData[]>([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [, setError] = useState<Error>();

  useSubscribe<Subscribable<UserData>>("users", setUsers, setError, sortName);

  const onUpdate = async (user: UserData) => {
    await updateItem<UserData & { id: string }>("users", user.uid, user);
  };
  const onApprove = async (userId: string) => {
    await updateItem<UserData & { id: string }>("users", userId, {
      approved: true,
    });
  };
  const onDeny = async (userId: string) => {
    await updateItem<UserData & { id: string }>("users", userId, {
      approved: false,
    });
  };

  const handleEdit = (user: UserData) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleDelete = (userId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("You sure?!")) deleteItem("users", userId);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleFormSubmit = () => {
    if (selectedUser) {
      onUpdate(selectedUser);
    }
    handleModalClose();
  };

  const handleInputChange = useCallback(
    (detail: BaseChangeDetail, field: keyof UserData) => {
      if (selectedUser) {
        setSelectedUser({ ...selectedUser, [field]: detail.value });
      }
    },
    [selectedUser]
  );

  useEffect(() => {
    handleInputChange({ value: selectedRole?.value ?? "user" }, "role");
  }, [selectedRole, handleInputChange]);

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          actions={
            userObject?.role === "god" && (
              <TenantSelect onChange={(x) => setSelectedTenant(x)} />
            )
          }
        >
          User Management
        </Header>
      }
    >
      <Table
        variant="full-page"
        columnDefinitions={[
          {
            id: "provider",
            header: "",
            cell: (item: UserData) =>
              item.authProvider === "google" ? (
                <RiGoogleFill />
              ) : item.authProvider === "microsoft" ? (
                <RiMicrosoftFill />
              ) : (
                ""
              ),
          },
          {
            id: "approved",
            header: "",
            cell: (item: UserData) => (item.approved ? "✅" : "⏳"),
          },
          { id: "name", header: "Name", cell: (item: UserData) => item.name },
          {
            id: "email",
            header: "Email",
            cell: (item: UserData) => item.email,
          },
          { id: "role", header: "Role", cell: (item: UserData) => item.role },
          {
            id: "tenant",
            header: "Tenant",
            cell: (item: UserData) => item.tenant,
          },
          {
            id: "actions",
            header: "Actions",
            minWidth: "200px",
            cell: (item: UserData) => (
              <SpaceBetween
                size="xxxs"
                // direction="horizontal"
                alignItems="center"
              >
                <Button onClick={() => onApprove(item.uid)}>Approve</Button>
                <Button onClick={() => onDeny(item.uid)}>Deny</Button>
                <Button onClick={() => handleEdit(item)}>Edit</Button>
                <Button onClick={() => handleDelete(item.uid)}>Delete</Button>
              </SpaceBetween>
            ),
          },
        ]}
        items={
          selectedTenant
            ? users.filter(
                (x) =>
                  x.tenant === selectedTenant ||
                  (selectedTenant === "trail" && !x.tenant)
              )
            : users.filter(
                (x) =>
                  x.tenant === userObject?.tenant ||
                  !x.tenant ||
                  x.tenant === "trail"
              )
        }
      />
      {selectedUser && (
        <Modal
          visible={isModalOpen}
          onDismiss={handleModalClose}
          header="Edit User"
        >
          <Form>
            <FormField label="Name">
              <Input
                name="name"
                value={selectedUser.name}
                onChange={(e) => handleInputChange(e.detail, "name")}
              />
            </FormField>
            <FormField label="Email">
              <Input
                name="email"
                readOnly
                value={selectedUser.email}
                onChange={(e) => handleInputChange(e.detail, "email")}
              />
            </FormField>
            <FormField label="Role">
              <Select
                disabled={selectedUser.role === "god"}
                selectedOption={{
                  label: selectedUser.role,
                  value: selectedUser.role,
                }}
                onChange={({ detail }) =>
                  setSelectedRole(detail.selectedOption)
                }
                options={[
                  { label: "Admin", value: "admin" },
                  { label: "User", value: "user" },
                  { label: "Approver", value: "approver" },
                ]}
              />
            </FormField>
            {userObject?.role === "god" && (
              <FormField label="Tenant">
                <TenantSelect
                  onChange={(t) => handleInputChange({ value: t }, "tenant")}
                />
              </FormField>
            )}
            <Spacer height={20} />
            <Button onClick={handleFormSubmit}>Save</Button>
          </Form>
        </Modal>
      )}
    </ContentLayout>
  );
};

export default UserTable;
