import React, { FC, useEffect, useState } from "react";

import { Select, SelectProps } from "@cloudscape-design/components";

import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import Tenant from "../models/Tenant";
import { sortName } from "../utils/sort";

interface ITenantSelect {
  onChange: (value: string) => void;
}

const TenantSelect: FC<ITenantSelect> = ({ onChange }) => {
  const { tenant } = useAuthContext();
  const [items, setItems] = useState<Tenant[]>([]);

  const [selected1, setSelected1] = useState<SelectProps.Option>({
    value: "No tenants found",
    label: "",
  });

  useSubscribe<Tenant>("tenant", setItems, () => {}, sortName);

  useEffect(() => {
    if (items && tenant) {
      const selectedTenant = items.find((x) => x.name === tenant);
      if (selectedTenant) onChange(tenant ?? "");
      setSelected1({ value: tenant, label: selectedTenant?.displayName });
    }
  }, [items, tenant]);

  return (
    <Select
      selectedOption={selected1}
      onChange={({ detail }) => {
        onChange(detail.selectedOption.value ?? "");
        setSelected1(detail.selectedOption);
      }}
      options={[
        { value: "trail", label: "No tenant" },
        ...(items ?? []).map((x) => {
          return { value: x.name, label: x.displayName };
        }),
      ]}
    />
  );
};

export default TenantSelect;
