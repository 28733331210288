import { Timestamp } from "@firebase/firestore";

import { Step } from "../models/Recipe";

export function toDate(input?: Date | Timestamp): Date {
  if (input instanceof Timestamp) {
    return input.toDate();
  } else if (input instanceof Date) {
    return input;
  } else {
    return new Date(0); // Return epoch date if input is undefined
  }
}

export function addDays(date: Date, daysToAdd: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
}

export function addDaysBySteps(date: Date, steps: Step[]): Date {
  let newDate = new Date(date);

  steps.forEach((step) => {
    // if endDate is set, return it, use diff between endDate and startDate insted of step.duration
    if (step.end) {
      newDate = toDate(step.end);
      return;
    }
    newDate = addDays(newDate, step.duration);
  });

  return newDate;
}
