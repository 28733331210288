import { FC, ReactNode } from "react";

interface IWrapper {
  children?: string | ReactNode | ReactNode[];
}

interface IRowCol extends IWrapper {
  align?: "start" | "end" | "center";
  justify?: "start" | "end" | "center";
}

interface IFlexProp extends IWrapper {
  align?: "start" | "end" | "center";
  column?: boolean;
}

const Flex: FC<IFlexProp> = ({ column, children, align }) => {
  const justifyContent =
    align === "start" ? "flex-start" : align === "end" ? "flex-end" : "center";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: column ? "column" : "row",
        justifyContent,
        gap: "1rem",
      }}
    >
      {children}
    </div>
  );
};

export const Column: FC<IRowCol> = ({ children, align }) => {
  const justifyContent =
    align === "start" ? "flex-start" : align === "end" ? "flex-end" : "center";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent,
        gap: "1rem",
      }}
    >
      {children}
    </div>
  );
};

export const Row: FC<IRowCol> = ({ children, align }) => {
  const justifyContent =
    align === "start" ? "flex-start" : align === "end" ? "flex-end" : "center";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent,
        gap: "1rem",
      }}
    >
      {children}
    </div>
  );
};

interface ISpace {
  w?: string | number;
  h?: string | number;
}

export const Space: FC<ISpace> = ({ w, h }) => {
  return (
    <div
      style={{
        width: w,
        height: h,
      }}
    />
  );
};

export default Flex;
