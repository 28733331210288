import { useEffect, useState } from "react";

import { Header, Table } from "@cloudscape-design/components";

import {
  retrieveAllInventory,
  SqInventoryItem,
  SqInventoryResponse,
} from "../../api/squarespace";

const InventoryView = ({ apiKey }: { apiKey?: string }) => {
  const [inventory, setInventory] = useState<SqInventoryResponse | null>();

  const fetchSqInventory = async () => {
    if (!apiKey) return;
    const result = await retrieveAllInventory(apiKey);
    setInventory(result);
  };

  useEffect(() => {
    if (apiKey) fetchSqInventory();
  }, [apiKey]);

  if (!apiKey) return null;

  return (
    <div>
      <Header>Squarespace Inventory</Header>
      <Table
        variant="embedded"
        columnDefinitions={[
          {
            id: "variantId",
            header: "Variant ID",
            cell: (item: SqInventoryItem) => item.variantId,
          },
          {
            id: "sku",
            header: "SKU",
            cell: (item: SqInventoryItem) => item.sku,
          },
          {
            id: "descriptor",
            header: "Descriptor",
            cell: (item: SqInventoryItem) => item.descriptor,
          },
          {
            id: "isUnlimited",
            header: "Is Unlimited",
            cell: (item: SqInventoryItem) => (item.isUnlimited ? "Yes" : "No"),
          },
          {
            id: "quantity",
            header: "Quantity",
            cell: (item: SqInventoryItem) => item.quantity,
          },
        ]}
        items={inventory?.inventory ?? []}
      />
    </div>
  );
};

export default InventoryView;
