import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment"; // For date formatting
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

import { Timestamp } from "@firebase/firestore";

import { Batch } from "../models/Batch";

interface CalendarWidgetProps {
  batches: Batch[];
}

interface Event {
  title: string;
  start: Date;
  end: Date;
  description: string;
}

const CalendarWidget: React.FC<CalendarWidgetProps> = ({ batches }) => {
  const localizer = momentLocalizer(moment);

  // Function to calculate the end date of a step based on its duration
  const calculateStepEndDate = (startDate: Date, duration: number): Date => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + duration);
    return endDate;
  };

  // Function to set a fixed 30-minute duration for the calendar event view
  const set30MinuteDuration = (startDate: Date): Date => {
    const endDate = new Date(startDate);
    endDate.setMinutes(startDate.getMinutes() + 30);
    return endDate;
  };

  // Prepare events for the calendar from batch steps
  const events: Event[] = batches.flatMap((batch) => {
    const recipeSteps = batch.recipe?.steps || [];
    let previousStepEndDate: Date | null =
      batch.startDate instanceof Timestamp ? batch.startDate.toDate() : null;

    return recipeSteps
      .map((step) => {
        let stepStartDate =
          step?.start instanceof Timestamp
            ? step.start.toDate()
            : previousStepEndDate;

        // If the current step is on the same day as the previous one, push the start time forward
        if (
          previousStepEndDate &&
          stepStartDate &&
          stepStartDate.toDateString() === previousStepEndDate.toDateString()
        ) {
          stepStartDate = new Date(previousStepEndDate.getTime()); // Push start time to after the previous step ends
          stepStartDate.setMinutes(stepStartDate.getMinutes() + 30); // Add 30 minutes to avoid overlap
        }

        // Calculate the actual end date for the step based on its duration
        const stepEndDate =
          step?.end instanceof Timestamp
            ? step.end.toDate()
            : stepStartDate
              ? calculateStepEndDate(stepStartDate, step.duration)
              : null;

        // If there's no valid start date, skip this step
        if (!stepStartDate) return null;

        // Set a 30-minute duration for how the event is displayed in the calendar
        const calendarEndDate = set30MinuteDuration(stepStartDate);

        // Update previousStepEndDate for the next step's calculation
        previousStepEndDate = stepEndDate || stepStartDate;

        return {
          title: `${batch.name}: ${step.name}`,
          start: stepStartDate,
          end: calendarEndDate, // Display a 30-minute duration in the calendar
          description: step.description || "No description",
        } as Event;
      })
      .filter((event): event is Event => event !== null); // Filter out null values
  });

  return (
    <div style={{ height: 600 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor={(event: Event) => event.start} // Access start date
        endAccessor={(event: Event) => event.end} // Access end date (fixed 30 minutes)
        titleAccessor={(event: Event) => event.title} // Access event title
        style={{ height: "100%" }}
        defaultView="month"
        views={["month"]}
      />
    </div>
  );
};

export default CalendarWidget;
