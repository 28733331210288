import { FC, JSX, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

import { BoardItem } from "@cloudscape-design/board-components";
import Board, { BoardProps } from "@cloudscape-design/board-components/board";
import {
  ContentLayout,
  Header,
  KeyValuePairs,
  Link,
} from "@cloudscape-design/components";

import { subscribeToBatchesByStatus } from "../api/batches";
import { fetchItemsWithFilter, Filter } from "../api/generic";
import Loader from "../components/Loader";
import { useAuthContext } from "../contexts/AuthContext";
import { Batch } from "../models/Batch";
import Tenant from "../models/Tenant";
import { exportLayout, StoredWidgetPlacement } from "../utils/layout";
import CalendarWidget from "../widgets/CalendarWidget";
import NextStepsWidget from "../widgets/NextStepsWidget";
import { boardI18nStrings } from "./i18n-strings";
import { HydroPiSample, iSpindelSample } from "../components/modules/HydroPi";
import { prefixCollection } from "../utils/prefix";
import { Timestamp } from "@firebase/firestore";
import TiltsWidget from "../widgets/TiltsWidget";
import ISpindelWidget from "../widgets/ISpindelWidget";

const dataInit = { id: "" };

type WidgetId =
  | "batchOverview"
  | "nextSteps"
  | "calendar"
  | "tilts"
  | "ispindels"
  | "default";

const defaultLayouts: ReadonlyArray<BoardProps.Item<StoredWidgetPlacement>> = [
  {
    id: "batchOverview",
    columnOffset: { 4: 0 },
    columnSpan: 2,
    rowSpan: 3,
    data: dataInit,
  },
  {
    id: "nextSteps",
    columnOffset: { 4: 2 },
    columnSpan: 2,
    rowSpan: 3,
    data: dataInit,
  },
  {
    id: "calendar",
    columnOffset: { 4: 0 },
    columnSpan: 4,
    rowSpan: 7,
    data: dataInit,
  },
  {
    id: "tilts",
    columnOffset: { 4: 2 },
    columnSpan: 2,
    rowSpan: 2,
    data: dataInit,
  },
  {
    id: "ispindels",
    columnOffset: { 4: 2 },
    columnSpan: 2,
    rowSpan: 2,
    data: dataInit,
  },
];

const Home: FC = () => {
  const { tenant, currentUser } = useAuthContext();
  const [tenantItem, setTenantItem] = useState<Tenant>();
  const [batches, setBatches] = useState<Batch[]>();
  const [tilts, setTilts] = useState<HydroPiSample[]>();
  const [iSpindels, setISpindels] = useState<iSpindelSample[]>();
  const [layout, setLayout] = useLocalStorage<
    ReadonlyArray<StoredWidgetPlacement>
  >("home-layout", defaultLayouts);

  const addMissingLayoutItems = (
    layout: ReadonlyArray<StoredWidgetPlacement>
  ) => {
    const missingItems = defaultLayouts.filter(
      (defaultItem) => !layout.find((item) => item.id === defaultItem.id)
    );
    const newLayout = [
      ...layout,
      ...missingItems.map((item) => ({ ...item, data: dataInit })),
    ];
    return newLayout;
  };

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    const newLayout = addMissingLayoutItems(layout);
    setLayout(newLayout);
  }

  const fetchTenant = async () => {
    const filteredItems = await fetchItemsWithFilter<Tenant>("tenant", [
      {
        field: "name",
        operator: "==",
        value: tenant,
      },
    ]);

    setTenantItem(filteredItems[0]);
  };

  const fetchTiltData = async () => {
    const filterLast48h: Filter<HydroPiSample>[] = [
      {
        field: "timestamp",
        operator: ">",
        value: Timestamp.fromDate(new Date(Date.now() - 48 * 60 * 60 * 1000)),
      },
    ];
    const tileData = await fetchItemsWithFilter<HydroPiSample>(
      prefixCollection("hydroPi", tenant, "-"),
      filterLast48h
    );

    const groupTiltsByColor = tileData.reduce(
      (acc, tilt) => {
        if (!acc[tilt.color]) {
          acc[tilt.color] = [];
        }
        acc[tilt.color].push(tilt);
        return acc;
      },
      {} as Record<string, HydroPiSample[]>
    );

    const latestRecordForEachColor = Object.keys(groupTiltsByColor).map(
      (color) =>
        groupTiltsByColor[color].sort(
          (a, b) => b.timestamp.seconds - a.timestamp.seconds
        )[0]
    );
    setTilts(latestRecordForEachColor);
  };

  const fetchISpindelData = async () => {
    const filterLast48h: Filter<iSpindelSample>[] = [
      {
        field: "timestamp",
        operator: ">",
        value: Timestamp.fromDate(new Date(Date.now() - 48 * 60 * 60 * 1000)),
      },
    ];
    const iSpindelData = await fetchItemsWithFilter<iSpindelSample>(
      prefixCollection("ispindel", tenant, "-"),
      filterLast48h
    );

    const groupISpindelsByColor = iSpindelData.reduce(
      (acc, iSpindel) => {
        if (!acc[iSpindel.color]) {
          acc[iSpindel.color] = [];
        }
        acc[iSpindel.color].push(iSpindel);
        return acc;
      },
      {} as Record<string, iSpindelSample[]>
    );

    const latestRecordForEachColor = Object.keys(groupISpindelsByColor).map(
      (color) =>
        groupISpindelsByColor[color].sort(
          (a, b) => b.timestamp.seconds - a.timestamp.seconds
        )[0]
    );

    setISpindels(latestRecordForEachColor);
  };

  useEffect(() => {
    fetchTenant();
    fetchTiltData();
    fetchISpindelData();
    if (layout) handleLayoutChange(layout);
  }, [tenant]);

  useEffect(() => {
    const unsubscribe = subscribeToBatchesByStatus(
      ["planned", "in-progress"],
      tenant,
      (batches) => setBatches(batches)
    );
    return unsubscribe;
  }, []);

  const totalActiveBatches = batches?.filter(
    (batch) => batch.status === "in-progress"
  ).length;
  const plannedBatches = batches?.filter(
    (batch) => batch.status === "planned"
  ).length;

  const dataForLayout: Record<
    WidgetId,
    {
      title: string;
      description: string;
      header: () => JSX.Element;
      content: () => JSX.Element;
    }
  > = {
    default: {
      title: "Widget not found",
      description:
        "The widget was not found in the widget collection. Seems one of our developers has had a few too many glasses of mead. We will fix it soon...",
      header: () => <Header>Obs...</Header>,
      content: () => <></>,
    },
    nextSteps: {
      title: "Next Steps",
      description: "Here are the next 5 steps for all batches",
      header: () => <Header>Upcoming steps</Header>,
      content: () => (
        <Loader isLoading={!batches}>
          <NextStepsWidget batches={batches ?? []} />
        </Loader>
      ),
    },
    calendar: {
      title: "Calendar",
      description: "Calendar",
      header: () => <Header>Calendar</Header>,
      content: () => (
        <Loader isLoading={!batches}>
          <CalendarWidget batches={batches ?? []} />
        </Loader>
      ),
    },
    batchOverview: {
      title: "Batch Overview",
      description: "Overview of active and planned batches",
      header: () => <Header>Batch Overview</Header>,
      content: () => (
        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "Active",
              value: (
                <Link variant="awsui-value-large" href="/batches">
                  {totalActiveBatches}
                </Link>
              ),
            },
            {
              label: "Planned",
              value: (
                <Header>
                  <Link variant="awsui-value-large" href="/planning">
                    {plannedBatches}
                  </Link>
                </Header>
              ),
            },
          ]}
        />
      ),
    },
    tilts: {
      title: `Tilts (${tilts?.length})`,
      description: "Tilt readings",
      header: () => <Header>Tilts</Header>,
      content: () => (tilts ? <TiltsWidget tilts={tilts} /> : <></>),
    },
    ispindels: {
      title: `iSpindels (${iSpindels?.length})`,
      description: "iSpindel readings",
      header: () => <Header>iSpindels</Header>,
      content: () =>
        iSpindels ? (
          <ISpindelWidget iSpindels={iSpindels}></ISpindelWidget>
        ) : (
          <></>
        ),
    },
  };

  const mappedLayout = layout
    ? layout.map((item) => ({
        ...item,
        data: dataForLayout[item.id as WidgetId] ?? dataForLayout.default,
      }))
    : [];

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header variant="h1" description={`${tenantItem?.displayName ?? ""}`}>
          Producery
        </Header>
      }
    >
      {!currentUser ? null : (
        <div style={{ marginTop: 10 }}>
          <Board
            items={mappedLayout}
            i18nStrings={boardI18nStrings}
            empty="No metrics available"
            onItemsChange={({ detail: { items } }) => {
              handleLayoutChange(exportLayout(items));
            }}
            renderItem={(item) => (
              <BoardItem
                i18nStrings={{
                  dragHandleAriaLabel: "Drag handle",
                  dragHandleAriaDescription:
                    "Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                  resizeHandleAriaLabel: "Resize handle",
                  resizeHandleAriaDescription:
                    "Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                }}
                header={
                  item.data.header ? (
                    <item.data.header />
                  ) : (
                    <Header>Board item title</Header>
                  )
                }
              >
                {item.data.content ? (
                  <item.data.content />
                ) : (
                  "Board item content"
                )}
              </BoardItem>
            )}
          />
        </div>
      )}
    </ContentLayout>
  );
};

export default Home;
